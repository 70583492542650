import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"

import { Collapse, Button, Checkbox, Input } from "antd"
import { useToasts } from "react-toast-notifications"
import { DownOutlined, UpOutlined } from "@ant-design/icons"

import RPMEditFormInput from "./RPMEditFormInput"
import Loading from "../../../../layouts/Loading/Loading"

import { useDispatch, useSelector } from "react-redux"
import { getPatientInfo } from "../../../../../redux/actions/patientAction"
import {
  getrpmCareplanDataActions,
  updateRPMUserActions
} from "../../../../../redux/actions/rpmAction"
import HandleApiFail from "../../../../layouts/APIFail/ApiFail"

const { Panel } = Collapse

const RPMEditMain = () => {
  // const [curentCatQues, setCurentCatQues] = useState([])
  const [initialDeviceQues, setInitialDeviceQues] = useState([])
  const [deviceQues, setDeviceQues] = useState([])
  const [conclusionQues, setConclusionQues] = useState([])
  const [showUpIconUpdate, setShowUpIconUpdate] = useState(true)
  const [showDownIconUpdate, setShowDownIconUpdate] = useState(true)
  const [rpmDataId, setRpmDataId] = useState(null)

  const [initialDeviceQuesDisabled, setInitialDeviceQuesDisabled] = useState(true)
  const [deviceQuesDisabled, setDeviceQuesDisabled] = useState(true)
  const [conclusionQuesDisabled, setConclusionQuesDisabled] = useState(true)

  const [enableTreatement] = useState(false)
  const [visibleUpdate, setVisibleUpdate] = useState(false)
  // const [reRenderer, setReRendered] = useState([])
  const [loading, setLoading] = useState(false)
  const [rpmData, setRpmData] = useState([])
  const [addingBy, setAddingBy] = useState({});

  const { id, orgId, orgName } = useParams()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const navigate = useNavigate()

  const userInfo = useSelector(state => state.userInfo);
  const { loading: rpmLoading, getRpmCarePlan } = useSelector(state => state.rpmPatientCarePlan)
  // const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
  
  const UserName = `${userInfo?.userInfo.firstName} ${userInfo?.userInfo.lastName}`;
  const addingBySecondary = { name: UserName, userType: "Caretaker" }

  useEffect(() => {
    if (!rpmLoading) {
      dispatch(getrpmCareplanDataActions(id, new Date().getFullYear()))
    }
  }, [dispatch, id])

  useEffect(() => {
    setRpmDataId(getRpmCarePlan && getRpmCarePlan[0].id)
    let latestPlan = getRpmCarePlan?.sort((a, b) => new Date(b.planDate) - new Date(a.planDate))[0]?.plan
    setRpmData(latestPlan)
    setAddingBy(latestPlan?.addingBy || addingBySecondary);
  }, [getRpmCarePlan])

  useEffect(() => {
    if (rpmData !== undefined && rpmData !== null && rpmData) {
      // const currentCatData = Object.values(rpmData?.careplan).filter((value) => value.rpmType === "CURRENT")
      const initialDeviceData = Object.values(rpmData).filter(
        value => value?.rpmType === "CONSENT" || value?.rpmType === "CURRENT" 
      )
      const deviceQuesData = Object.values(rpmData).filter(value => value?.devType || value?.device)
      const conclusionQuesData = Object.values(rpmData).filter(value =>
        value?.rpmType ? value?.rpmType === "CONCLUSION" : value?.ccmType === "CONCLUSION"
      )
      // setCurentCatQues(currentCatData)
      setInitialDeviceQues(initialDeviceData)
      setDeviceQues(deviceQuesData)
      setConclusionQues(conclusionQuesData)
    }
  }, [rpmData])

  // question types functions
  const handleNormalCheck = (quesId, i, e, Ques) => {
    // console.log(e.target.checked,quesId,i)
    const newState = [...Object.values(rpmData)]
    const findIndex = newState.findIndex(
      x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].questionaryOptions[i].checked = e.target.checked
    if (newState[findIndex].questionaryOptions[i].alert !== undefined) {
      if (newState[findIndex].questionaryOptions[i].checked === true) {
        if (
          newState[quesId].device !== undefined &&
          newState[quesId].device === "blood pressure machine"
        ) {
          window.alert("Advise the patient to check their blood pressure now.")
        }
      }
    }
    newState[findIndex].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (newState[findIndex].anyValue = true)
      }
    })
    const updateContent = newState
    setRpmData(updateContent)
  }

  const handleText = (quesId, i, e, Ques) => {
    const newState = [...Object.values(rpmData)]
    const findIndex = newState.findIndex(
      x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].questionaryOptions[i].text = e.target.value
    newState[findIndex].questionaryOptions[i].text !== ""
      ? (newState[findIndex].anyValue = true)
      : (newState[findIndex].anyValue = false)

    const updateContent = newState
    setRpmData(updateContent)
  }

  const handleNormalText = (quesId, e, Ques) => {
    const newState = [...Object.values(rpmData)]
    const findIndex = newState.findIndex(
      x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].text = e.target.value
    newState[findIndex].text !== ""
      ? (newState[findIndex].anyValue = true)
      : (newState[findIndex].anyValue = false)

    const updateContent = newState
    setRpmData(updateContent)
  }

  const handleSingle = (quesId, i, e, Ques) => {
    const newState = [...Object.values(rpmData)]
    const findIndex = newState.findIndex(
      x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].questionaryOptions.forEach(item => {
      if (item !== newState[findIndex].questionaryOptions[i]) {
        item.checked = false
      }
    })
    newState[findIndex].questionaryOptions[i].checked = e.target.checked
    newState[findIndex].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (newState[findIndex].anyValue = true)
      }
    })
    const updateContent = newState
    setRpmData(updateContent)
  }

  const handleBool = (quesId, e, Ques) => {
    const newState = [...Object.values(rpmData)]
    const findIndex = newState.findIndex(
      x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].questionaryOptions[0] = e.target.value
    newState[findIndex].questionaryOptions.length
      ? (newState[findIndex].anyValue = true)
      : (newState[findIndex].anyValue = false)
    const updateContent = newState
    setRpmData(updateContent)
  }

  const handleBpmNone = (quesId, i, e, Ques) => {
    const newState = [...Object.values(rpmData)]
    const findIndex = newState.findIndex(
      x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].questionaryOptions[i].checked = e.target.checked
    newState[findIndex].questionaryOptions.forEach(item => {
      if (
        item.name !== "None of the above" &&
        newState[findIndex].questionaryOptions[i].checked === true
      ) {
        item.disabled = true
        item.checked = false
        item.text = ""
      } else {
        item.disabled = false
      }
    })
    newState[findIndex].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (newState[findIndex].anyValue = true)
      }
    })
    const updateContent = newState
    setRpmData(updateContent)
  }

  //= >used for both weight machine and oximeter
  const handleWeightMachineCheck = (quesId, i, e, ii, iii, Ques) => {
    // i index first
    // ii index of sub child
    // iii index of sub sub child
    let newState
    let findIndex
    if (iii !== undefined && Ques !== undefined) {
      newState = [...Object.values(rpmData)]
      findIndex = newState.findIndex(
        x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
      )

      newState[findIndex].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii].checked =
        e.target.checked

      // alert
      if (
        newState[findIndex].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii].alert !==
        undefined
      ) {
        if (
          newState[findIndex].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii]
            .checked === true
        ) {
          if (
            newState[findIndex].device !== undefined &&
            newState[findIndex].device === "weight machine"
          ) {
            window.alert("Advise the patient to check their weight now.")
          }
          if (
            newState[findIndex].device !== undefined &&
            newState[findIndex].device === "oximeter" &&
            i < 3 &&
            ii === 3
          ) {
            window.alert("Advise the patient to check their oxygen level now.")
          }
          if (
            newState[findIndex].device !== undefined &&
            newState[findIndex].device === "oximeter" &&
            i > 2 &&
            ii === 3
          ) {
            window.alert("Advise the patient to check their heart rate now.")
          }
        }
      }
    } else {
      newState = [...Object.values(rpmData)]
      findIndex = newState.findIndex(
        x => x?.rpmType === ii.rpmType && x?.name === ii.name && x?.id === ii.id
      )

      newState[findIndex].questionaryOptions[i].checked = e.target.checked
    }
    newState[findIndex].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (newState[findIndex].anyValue = true)
      }
    })
    const updateContent = newState
    setRpmData(updateContent)
  }

  //= >used for both weight machine and oximeter
  const handleWeightMachineOtherText = (quesId, i, e, ii, iii, Ques) => {
    const newState = [...Object.values(rpmData)]
    const findIndex = newState.findIndex(
      x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii].text =
      e.target.value
    newState[findIndex].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (newState[findIndex].anyValue = true)
      }
    })
    const updateContent = newState
    setRpmData(updateContent)
  }

  //= >used for both weight machine and oximeter
  const handleWeightMachineNone = (quesId, i, e, ii, iii, Ques) => {
    const newState = [...Object.values(rpmData)]
    const findIndex = newState.findIndex(
      x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii].checked =
      e.target.checked
    newState[findIndex].questionaryOptions[i].subQuestion[ii].questionaryOptions.map(item => {
      if (
        item.name !== "None of the above" &&
        newState[findIndex].questionaryOptions[i].subQuestion[ii].questionaryOptions[iii]
          .checked === true
      ) {
        item.disabled = true
        item.checked = false
        item.text = ""
      } else {
        item.disabled = false
      }
      return item
    })
    newState[findIndex].questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (newState[findIndex].anyValue = true)
      }
    })
    const updateContent = newState
    setRpmData(updateContent)
  }

  const handleNotApplicable = (quesId, e, Ques) => {
    const newState = [...Object.values(rpmData)]
    const findIndex = newState.findIndex(
      x => x?.rpmType === Ques?.rpmType && x?.name === Ques?.name && x?.id === Ques?.id
    )

    newState[findIndex].notApplicable = e.target.checked

    const updateContent = newState
    setRpmData(updateContent)
  }

  console.log("date", getRpmCarePlan?.planDate)
  const submitForm = async () => {
    setLoading(true)
    const rpmFilteredData = rpmData.filter((item) => item.id)
    const planDate = getRpmCarePlan[0]?.planDate
    dispatch(updateRPMUserActions(id, { ...rpmFilteredData, addingBy: addingBy }, rpmDataId , planDate)).then(res => {
      if (res?.message?.toLowerCase() === "success") {
        setRpmData(null)
        addToast("Treatement Plan Updated Successfully", {
          appearance: "success",
          autoDismiss: true
        })
        navigate(`/${orgId}/${orgName}/rpm/patients`)
        setLoading(false)
      }
    })
  }
  /// //////////////////////
  // handling section disabling

  const handleInitialDevice = () => {
    setInitialDeviceQuesDisabled(!initialDeviceQuesDisabled)
  }
  const handleDevice = () => {
    setDeviceQuesDisabled(!deviceQuesDisabled)
  }
  const handleConclusion = () => {
    setConclusionQuesDisabled(!conclusionQuesDisabled)
  }
  /// ///////////////////////////////////
  return rpmLoading ? (
    <Loading />
  ) : (
    <div className="ccm_all_card">
      <Collapse accordion>
        <Panel header="Device Frequency" key="1">
          <div>
            <div>
              {initialDeviceQues && initialDeviceQues.length
                ? initialDeviceQues?.map((ques, index) => {
                  return (
                    <div key={index} className="p-3 mb-2">
                      <h6 className="text-lg font-medium">Question:{index + 1}</h6>
                      <h4 className="text-lg mt-3 mb-2">{ques?.name}</h4>
                      <h5 className="text-lg mb-4">{ques?.title}</h5>
                      <RPMEditFormInput
                        ques={ques}
                        quesDisabled={initialDeviceQuesDisabled}
                        handleNormalCheck={handleNormalCheck}
                        handleText={handleText}
                        handleNormalText={handleNormalText}
                        handleSingle={handleSingle}
                        handleBool={handleBool}
                        handleBpmNone={handleBpmNone}
                        handleWeightMachineCheck={handleWeightMachineCheck}
                        handleWeightMachineOtherText={handleWeightMachineOtherText}
                        handleWeightMachineNone={handleWeightMachineNone}
                      />
                      <div className="not_applicable mt-4">
                        {ques?.notApplicable !== undefined && (
                          <Checkbox
                            checked={!!ques?.notApplicable}
                            disabled={initialDeviceQuesDisabled}
                            onChange={e => handleNotApplicable(ques?.id, e, ques)}>
                            Not Applicable
                          </Checkbox>
                        )}
                      </div>
                    </div>
                  )
                })
                : null}
            </div>
            <div className="d-flex justify-content-end">
              <Button size="large" type="primary" className="my-2" onClick={handleInitialDevice}>
                {initialDeviceQuesDisabled ? "Any changes to current question?" : "Save"}
              </Button>
            </div>
          </div>
        </Panel>

        <Panel header="RPM Devices" key="2">
          <div>
            <div>
              {deviceQues && deviceQues.length
                ? deviceQues?.map((ques, index) => {
                  return (
                    <div key={index} className="p-3 mb-2">
                      <h6 className="text-lg font-medium">Question:{index + 1}</h6>
                      <h4 className="text-lg mt-2 text-current capitalize">{ques?.device}</h4>
                      <h4 className="text-lg my-3">{ques?.name}</h4>
                      <h5 className="text-lg mb-4">{ques?.title}</h5>
                      <RPMEditFormInput
                        ques={ques}
                        quesDisabled={deviceQuesDisabled}
                        handleNormalCheck={handleNormalCheck}
                        handleText={handleText}
                        handleNormalText={handleNormalText}
                        handleSingle={handleSingle}
                        handleBool={handleBool}
                        handleBpmNone={handleBpmNone}
                        handleWeightMachineCheck={handleWeightMachineCheck}
                        handleWeightMachineOtherText={handleWeightMachineOtherText}
                        handleWeightMachineNone={handleWeightMachineNone}
                      />
                      <div className="not_applicable mt-4">
                        {ques?.notApplicable !== undefined && (
                          <Checkbox
                            disabled={deviceQuesDisabled}
                            checked={!!ques?.notApplicable}
                            onChange={e => handleNotApplicable(ques?.id, e, ques)}>
                            Not Applicable
                          </Checkbox>
                        )}
                      </div>
                    </div>
                  )
                })
                : null}
            </div>
            <div className="d-flex justify-content-end">
              <Button size="large" type="primary" className="my-2" onClick={handleDevice}>
                {deviceQuesDisabled ? "Any changes to current question?" : "Save"}
              </Button>
            </div>
          </div>
        </Panel>

        <Panel header="Conclusion" key="3">
          <div>
            <div>
              {conclusionQues && conclusionQues.length
                ? conclusionQues?.map((ques, index) => {
                  return (
                    <div key={index} className="p-3 mb-2">
                      <h6 className="text-lg font-medium">Question:{index + 1}</h6>
                      <h4 className="text-lg mt-3 mb-2">
                        {" "}
                        {index + 1}. {ques?.name}
                      </h4>
                      <h5 className="text-lg mb-4">{ques?.title}</h5>

                      <div>
                        <RPMEditFormInput
                          ques={ques}
                          quesDisabled={conclusionQuesDisabled}
                          handleNormalCheck={handleNormalCheck}
                          handleText={handleText}
                          handleNormalText={handleNormalText}
                          handleSingle={handleSingle}
                          handleBool={handleBool}
                          handleBpmNone={handleBpmNone}
                          handleWeightMachineCheck={handleWeightMachineCheck}
                          handleWeightMachineOtherText={handleWeightMachineOtherText}
                          handleWeightMachineNone={handleWeightMachineNone}
                        />
                        <div className="not_applicable mt-4">
                          {ques?.notApplicable !== undefined && (
                            <Checkbox
                              disabled={conclusionQuesDisabled}
                              checked={!!ques?.notApplicable}
                              onChange={e => handleNotApplicable(ques?.id, e, ques)}>
                              Not Applicable
                            </Checkbox>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })
                : null}
            </div>
            <div className="d-flex justify-content-end">
              <Button size="large" type="primary" className="my-2" onClick={handleConclusion}>
                {conclusionQuesDisabled ? "Any changes to current question?" : "Save"}
              </Button>
            </div>
            {enableTreatement && (
              <div className="">
                <div className="">
                  <h6 className="mb-0 pb-0">Update Notes</h6>
                  {showDownIconUpdate && (
                    <DownOutlined
                      className="cursor-pointer"
                      onClick={() => {
                        setVisibleUpdate(true)
                        setShowDownIconUpdate(false)
                      }}
                    />
                  )}
                  {visibleUpdate && showUpIconUpdate && (
                    <UpOutlined
                      className="cursor-pointer"
                      onClick={() => {
                        setVisibleUpdate(false)
                        setShowUpIconUpdate(true)
                        setShowDownIconUpdate(true)
                      }}
                    />
                  )}
                </div>
                {visibleUpdate && enableTreatement && (
                  <div className="note">
                    <Input.TextArea placeholder="Enter Notes Here..." />
                  </div>
                )}
              </div>
            )}
          </div>
        </Panel>
      </Collapse>

      <div className="flex justify-end mt-4">
        <Button size="large" type="primary" onClick={submitForm} disabled={loading}>
          Submit
        </Button>
      </div>
    </div>
  )
}

export default RPMEditMain
