// ccm
export const GET_RPM_REQUEST = "GET_RPM_REQUEST"
export const GET_RPM_SUCCESS = "GET_RPM_SUCCESS"
export const GET_RPM_FAIL = "GET_RPM_FAIL"

export const POST_RPM_USER_REQUEST = "POST_RPM_USER_REQUEST"
export const POST_RPM_USER_SUCCESS = "POST_RPM_USER_SUCCESS"
export const POST_RPM_USER_FAIL = "POST_RPM_USER_FAIL"

export const GET_RPM_CAREPLAN_REQUEST = "GET_RPM_CAREPLAN_REQUEST"
export const GET_RPM_CAREPLAN_SUCCESS = "GET_RPM_CAREPLAN_SUCCESS"
export const GET_RPM_CAREPLAN_FAIL = "GET_RPM_CAREPLAN_FAIL"

export const GET_RPM_FOLLOW_UP_REQUIEST = "GET_RPM_FOLLOW_UP_REQUIEST"
export const GET_RPM_FOLLOW_UP_SUCCESS = "GET_RPM_FOLLOW_UP_SUCCESS"
export const GET_RPM_FOLLOW_UP_FAIL = "GET_RPM_FOLLOW_UP__FAIL"

export const POST_RPM_TRIAGE_REQUEST = "POST_RPM_TRIAGE_REQUEST"
export const POST_RPM_TRIAGE_SUCCESS = "POST_RPM_TRIAGE_SUCCESS"
export const POST_RPM_TRIAGE_FAIL = "POST_RPM_TRIAGE_FAIL"

export const GET_RPM_DEVICE_REQUEST = "GET_RPM_DEVICE_REQUEST"
export const GET_RPM_DEVICE_SUCCESS = "GET_RPM_DEVICE_SUCCESS"
export const GET_RPM_DEVICE_FAIL = "GET_RPM_DEVICE_FAIL"

export const POST_RPM_CLEAR_TRIAGE_REQUEST = "POST_RPM_CLEAR_TRIAGE_REQUEST"
export const POST_RPM_CLEAR_TRIAGE_SUCCESS = "POST_RPM_CLEAR_TRIAGE_SUCCESS"
export const POST_RPM_CLEAR_TRIAGE_FAIL = "POST_RPM_CLEAR_TRIAGE_FAIL"

export const GET_RPM_TRIAGE_DATA_REQUEST = "GET_RPM_TRIAGE_DATA_REQUEST"
export const GET_RPM_TRIAGE_DATA_SUCCESS = "GET_RPM_TRIAGE_DATA_SUCCESS"
export const GET_RPM_TRIAGE_DATA_FAIL = "GET_RPM_TRIAGE_DATA_FAIL"

export const POST_CRITICAL_READING_SNOOZE_REQUEST = "POST_CRITICAL_READING_SNOOZE_REQUEST"
export const POST_CRITICAL_READING_SNOOZE_SUCCESS = "POST_CRITICAL_READING_SNOOZE_SUCCESS"
export const POST_CRITICAL_READING_SNOOZE_FAIL = "POST_CRITICAL_READING_SNOOZE_FAIL"

export const ADD_CRITICAL_PATIENT_REQUEST = "ADD_CRITICAL_PATIENT_REQUEST"
export const ADD_CRITICAL_PATIENT_SUCCESS = "ADD_CRITICAL_PATIENT_SUCCESS"
export const ADD_CRITICAL_PATIENT_FAIL = "ADD_CRITICAL_PATIENT_FAIL"
export const ADD_CRITICAL_PATIENT_RESET = "ADD_CRITICAL_PATIENT_RESET"