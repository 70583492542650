import React, { useEffect, useState, memo } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { HiDocumentRemove, HiLightBulb } from "react-icons/hi"

import Loading from "../../layouts/Loading/Loading"
import formatPhoneNumber from "../../../utils/formatPhoneNumber"

import { Table, Input, Button, Card, Tooltip, Typography, Tabs, Row } from "antd"

import { GoAlert } from "react-icons/go"
import { useToasts } from "react-toast-notifications"

import { useDispatch, useSelector } from "react-redux"
import { getTriageCriticalPatientsAction } from "../../../redux/actions/patientAction"
import moment from "moment"
import { LeftOutlined } from "@ant-design/icons"
import AddCriticalPatient from "./AddCriticalPatient"

const { Search } = Input

export const isElapsedGreaterThan4Hours = prevTime => {
    const currentTime = new Date();
    const elapsedTime = currentTime.getTime() - new Date(prevTime).getTime();
    // Check if elapsed time is less than 4 hours (in milliseconds)
    const isGreaterThan4Hours = elapsedTime >= 4 * 60 * 60 * 1000;
    // console.log(`Elapsed time greater than or equal to 4 hours: ${isGreaterThan4Hours}`);
    return isGreaterThan4Hours;
};

const RECEIVED = "received";
const RESOLVED = "resolved";

const TriagePatients = () => {
    const [showAddCriticalpatient, setShowAddCriticalpatient] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { orgId, orgName } = useParams()

    const [RpmData, setRpmData] = useState([])
    const [serachedData, setSearchedData] = useState([])
    const [serachTerm, setSearchTerm] = useState("")
    const [activeTriageTab, setActiveTriageTab] = useState("received")
    const [receivedCount, setReceivedCount] = useState(0)
    const [resolvedCount, setResolvedCount] = useState(0)

    const { loading, triageCriticalPatients: criticalPatient } = useSelector(
        state => state.triageCriticalPatients
    )
    const { addToast } = useToasts()

    useEffect(() => {
        dispatch(getTriageCriticalPatientsAction(orgId))
    }, [dispatch])

    const handleCriticalDeviceAlert = device => {
        return addToast(`This patient got critical reading from : ${device}`, {
            appearance: "info",
            autoDismiss: true
        })
    }

    useEffect(() => {
        if (criticalPatient !== undefined && criticalPatient !== null) {

            let receivedData = []
            let resolvedData = []
            let receivedSno = 1
            let resolvedSno = 1
            criticalPatient?.sort((a, b) => moment(b.criticalDate) - moment(a.criticalDate))?.filter(
                ({
                    firstName,
                    middleName,
                    lastName,
                    id,
                    criticalStatus,
                    criticalDate,
                    email,
                    mobileNumber
                }) => {
                    if (criticalStatus) {
                        middleName
                            ? receivedData.push({
                                key: receivedSno,
                                sNo: receivedSno,
                                name: firstName + " " + middleName + " " + lastName,
                                id: id,
                                criticalStatus: criticalStatus,
                                criticalDate: criticalDate,
                                email: email,
                                phone: mobileNumber
                            })
                            : receivedData.push({
                                key: receivedSno,
                                sNo: receivedSno,
                                name: firstName + " " + lastName,
                                id: id,
                                criticalStatus: criticalStatus,
                                criticalDate: criticalDate,
                                email: email,
                                phone: mobileNumber
                            })
                        receivedSno += 1
                    } else {
                        middleName
                            ? resolvedData.push({
                                key: resolvedSno,
                                sNo: resolvedSno,
                                name: firstName + " " + middleName + " " + lastName,
                                id: id,
                                criticalStatus: criticalStatus,
                                criticalDate: criticalDate,
                                email: email,
                                phone: mobileNumber
                            })
                            : resolvedData.push({
                                key: resolvedSno,
                                sNo: resolvedSno,
                                name: firstName + " " + lastName,
                                id: id,
                                criticalStatus: criticalStatus,
                                criticalDate: criticalDate,
                                email: email,
                                phone: mobileNumber
                            })
                        resolvedSno += 1
                    }
                }
            )
            setReceivedCount(receivedSno - 1)
            setResolvedCount(resolvedSno - 1)
            setRpmData(activeTriageTab === RECEIVED ? receivedData : resolvedData)
        }
    }, [criticalPatient, activeTriageTab])

    let currDate = new Date().toDateString()
    // column for table
    const columns = [
        {
            title: "S.No.",
            dataIndex: "sNo",
            key: "sNo"
        },
        {
            title: "Full Name",
            dataIndex: "name",
            key: "name",
            render: (name, { id }) => (
                <p
                    className="capitalize"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/${orgId}/${orgName}/critical-patient-readings/${id}`, {
                        state: {
                            name: name
                        }
                    })}>
                    {name}
                </p>
            ),
            sorter: (a, b) => a?.name.localeCompare(b?.name)
        },
        {
            title: "Critical Status time",
            dataIndex: "criticalDate",
            key: "criticalDate",
            render: (criticalDate) => (
                <div className="w-full flex gap-2">
                    {!criticalDate && criticalDate === "" ? "-" :
                        <span>{moment(criticalDate).format("MM/DD/YYYY [at] hh:mm A")}</span>
                    }
                </div>
            ),
            sorter: (a, b) => moment(a?.criticalDate) - moment(b?.criticalDate)
        },
        // {
        //     title: "Category Of Criticality",
        //     dataIndex: "catogoryOfCriticality",
        //     key: "catogoryOfCriticality",
        //     render: (catogoryOfCriticality) => (
        //         <div className="w-full flex gap-2">
        //             {!catogoryOfCriticality ? "-" :
        //                 <span>{moment(catogoryOfCriticality).format("MM/DD/YYYY [at] hh:mm A")}</span>
        //             }
        //         </div>
        //     )
        // },
        {
            title: "Status Of Triage",
            dataIndex: "criticalStatus",
            key: "criticalStatus",
            render: (criticalStatus) => (
                <div className="w-full flex gap-2">
                    <span>{criticalStatus ? "Received" : "Resolved"}</span>
                </div>
            )
        },
    ]

    const triageTabItems = [
        {
            key: "received",
            label: `Received (${receivedCount})`
        },
        {
            key: "resolved",
            label: `Resolved (${resolvedCount})`
        }
    ]

    const handleSearch = e => {
        const query = e?.target?.value
        let queryWithoutSpace = query?.replace(/^\s+/g, "")
        queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
        setSearchTerm(queryWithoutSpace)
        const trimmedSearchValue = query.replace(/\s+/g, "").toLowerCase()
        const prevData = RpmData
        const newData = prevData.filter(item => {
            return (
                item.name?.replace(/\s+/g, "").toLowerCase().includes(trimmedSearchValue) ||
                moment(item?.criticalDate).format("MM/DD/YYYY").includes(trimmedSearchValue) ||
                item?.email?.includes(trimmedSearchValue) ||
                item?.phone?.includes(trimmedSearchValue) ||
                formatPhoneNumber(item?.phone)?.includes(query?.trim().toLowerCase())
            )
        })
        if (newData.length) {
            setSearchedData(newData)
        } else {
            setSearchedData([])
        }
    }

    return loading ? (
        <div className="loaderLoading">
            <Loading />
        </div>
    ) : (
        <>
            <Typography.Text
                strong
                className="cursor-pointer"
                onClick={() => {
                    navigate("/")
                }}>
                <LeftOutlined />
                &nbsp; Organization
            </Typography.Text>
            <Card className="rounded-b-none my-3">
                <div className="d-flex justify-content-between">
                    <h3 className="text-xl">Triage</h3>
                    <Search
                        allowClear
                        size="large"
                        placeholder="Search"
                        style={{ width: 350 }}
                        value={serachTerm}
                        // onSearch={value => handleSearch(value)}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
            </Card>
            <Row className="flex justify-between items-center">
                <Tabs
                    defaultActiveKey="received"
                    items={triageTabItems}
                    onChange={key => setActiveTriageTab(key)}
                    className="mb-3"
                />
                <div className="flex justify-end items-center mb-3">
                    <Button type="primary" size="large" onClick={() => setShowAddCriticalpatient(true)}>
                        Add Critical Reading
                    </Button>
                </div>
            </Row>
            <Table
                columns={columns}
                scroll={{ x: "max-content" }}
                dataSource={serachTerm ? serachedData : RpmData}
            />
            {showAddCriticalpatient &&
                <AddCriticalPatient
                    showAddCriticalpatient={showAddCriticalpatient}
                    setShowAddCriticalpatient={setShowAddCriticalpatient}
                />
            }
        </>
    )
}

export default TriagePatients