import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import styled from 'styled-components';
import { searchUserWithTerm } from '../../redux/actions/userSearchActions';
import './userSearch.css';

const SearchContainer = styled.form`
  height: 40px;
  display: flex;
  align-items: center;
  max-width: 500px;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
  height: 32px;
  margin-left: 8px;
  padding: 0 12px;
`;

const UserSearch = () => {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const { loading, userInfo } = useSelector((state) => state.getUserSearch);
 

  const handleSearch = (e) => {
    const query = e?.target?.value;
    let queryWithoutSpace = query?.replace(/^\s+/g, '');
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, ' ');
    setQuery(queryWithoutSpace);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    if (!query) return;

    let formattedQuery = query;
    const userViewIdRegex = /^O\d+$/;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    
    if (userViewIdRegex.test(query)) {
      formattedQuery = query;
    } else if (phoneRegex.test(query)) {
      formattedQuery = query.replace(/\D/g, '');
    } else {
      formattedQuery = query.trim().toLowerCase();
    }
    dispatch(searchUserWithTerm(formattedQuery));
  };

  const getUniqueOrganizations = () => {
    const uniqueOrganizations = Array.from(
      new Set(
        Array.isArray(userInfo)
          ? userInfo
              .filter((user) => user.orgName)
              .map((user) => user.orgName)
          : []
      )
    );

    return uniqueOrganizations.map((orgName) => ({
      text: orgName,
      value: orgName,
    }));
  };

  // const getUniqueProfileTypes = () => {
  //   const uniqueProfiles = Array.from(
  //     new Set(
  //       Array.isArray(userInfo)
  //         ? userInfo
  //             .filter((user) => user.profileType)
  //             .map((user) => user.profileType)
  //         : []
  //     )
  //   );
  //   return uniqueProfiles.map((profileType) => ({
  //     text: profileType,
  //     value: profileType,
  //   }));
  // };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) =>
        record?.profileType?.toLowerCase() === 'patient' ? (
          <Link to={`/${record.orgId}/${record.orgName}/patient/${record?.id}`} target="_blank" className="capitalize">
            {`${record?.firstName} ${record?.middleName ?? ''} ${record?.lastName}`}
          </Link>
        ) : (
          <span className="capitalize">
            {`${record?.firstName} ${record?.middleName ?? ''} ${record?.lastName}`}
          </span>
        ),
    },
    {
      title: 'Organization Name',
      dataIndex: 'orgName',
      key: 'orgName',
      filters: getUniqueOrganizations(),
      onFilter: (value, record) =>
        record.orgName.toLowerCase() === value.toLowerCase(),
      render: (text) => <span className="capitalize">{text}</span>,
    },
    {
      title: 'Profile Type',
      dataIndex: 'profileType',
      key: 'profileType',
      // filters: getUniqueProfileTypes(),
      // onFilter: (value, record) =>
      //   record.profileType.toLowerCase() === value.toLowerCase(),
      render: (text) => <>{`${text.toUpperCase()}`}</>,
    },
    {
      title: 'ID',
      dataIndex: 'userViewId',
      key: 'userViewId',
    },
  ];

  return (
    <div>
      <SearchContainer onSubmit={handleSearchClick}>
        <Input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={(e) => handleSearch(e)}
          allowClear
        />
        <StyledButton
          type="primary"
          htmlType="submit"
          icon={<SearchOutlined />}
          size="large"
          disabled={Boolean(!query?.length)}
        >
          Search
        </StyledButton>
      </SearchContainer>
      <div className="app-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {userInfo && userInfo?.length > 0 ? (
              <Table
                loading={loading}
                dataSource={userInfo}
                columns={columns}
                scroll={{ x: 'max-content' }}
                rowKey={(record) => record.id}
                showSorterTooltip={true}
                pagination={false}
              />
            ) : (
              <>{userInfo?.length === 0 ? <p>No User Found!</p> : <></>}</>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserSearch;

