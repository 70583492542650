import { useState, useEffect } from "react"

import { Card } from "antd"

import { useSelector, useDispatch } from "react-redux"

import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { ProTable } from "@ant-design/pro-components"

import "./LastReadingAnalysis.css"
import { formatAMPM } from "../../utils/formatDate"
import { getLastReadingTakenAnalysisReport } from "../../redux/actions/LastReadingTakenAnalysisAction"
import SelectOrgDropdown from "../../utils/SelectOrgDropdown"

function LastReadingAnalysis() {
  const [selectedOrg, setSelectedOrg] = useState(null)
  const [tableData, setTableData] = useState([])
  const [backupData, setBackupData] = useState([])

  const dispatch = useDispatch()
  const { readingAnalysis, loading: readingLoading } = useSelector(
    state => state.lastReadingAnalysis
  )
  const { loading: orgLoading } = useSelector(state => state.orgDetails)

  const proColumns = [
    {
      disable: true,
      title: "ID",
      dataIndex: "userViewId",
      copyable: true,
      width: "160px"
    },
    {
      disable: true,
      title: "Organization",
      width: "10%",
      dataIndex: "organization"
    },
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Email",
      dataIndex: "email"
    },
    {
      title: "Contact Number",
      dataIndex: "mobileNumber"
    },
    {
      title: "CCM (TRUE/FALSE)",
      dataIndex: "ccm",
      onFilter: true,
      valueType: "select",
      valueEnum: {
        True: { text: "True" },
        False: { text: "False" }
      }
    },
    {
      title: "RPM (TRUE/FALSE)",
      dataIndex: "rpm",
      valueEnum: {
        True: { text: "True" },
        False: { text: "False" }
      }
    },
    {
      title: "PCM (TRUE/FALSE)",
      dataIndex: "pcm",
      valueEnum: {
        True: { text: "True" },
        False: { text: "False" }
      }
    },
    {
      title: "RTM (TRUE/FALSE)",
      dataIndex: "rtm",
      valueEnum: {
        True: { text: "True" },
        False: { text: "False" }
      },
      render: value => <p>{value}</p>
    },
    {
      title: "Last Reading Taken on Date",
      hideInSearch: true,
      dataIndex: "latestReadingDate",
      sorter: (a, b) => {
        const dateA = a.latestReadingDate ? new Date(a.latestReadingDate).getTime() : null
        const dateB = b.latestReadingDate ? new Date(b.latestReadingDate).getTime() : null

        if (dateA === null && dateB === null) return 0 // Both are null, so they are equal
        if (dateA === null) return 1 // Place nulls at the end
        if (dateB === null) return -1 // Place nulls at the end

        // Sort in descending order based on timestamps
        return dateB - dateA // Correctly sort from high to low
      },
      render: value => {
        // Handle null or undefined values
        if (!value) return <p>N/A</p>

        // Check if value is a string and potentially a valid date
        if (typeof value === "string") {
          const date = new Date(value)
          if (isNaN(date.getTime())) return <p> - </p> // Check for invalid Date

          // Format the valid date
          const formattedDate = formatAMPM(date)
          return <p>{formattedDate}</p>
        }

        // If value is a number, assume it's a timestamp and create a Date object
        if (typeof value === "number") {
          const date = new Date(value)
          if (isNaN(date.getTime())) return <p> - </p> // Check for invalid Date

          // Format the valid date
          const formattedDate = formatAMPM(date)
          return <p>{formattedDate}</p>
        }

        // Handle cases where value is neither a string nor a number
        return <p> - </p>
      }
    },
    {
      title: "Equipment Status",
      dataIndex: "equipmentStatus",
      valueType: "select",
      valueEnum: Object.fromEntries(
        [
          "Will pick up from clinic",
          "Picked up from clinic",
          "Delivery requested",
          "In transit",
          "Delivered",
          "Equipment active",
          "Facing equipment issue",
          "Equipment missing"
        ]?.map(key => [key, { text: key }])
      )
    },
    {
      title: "Patient Status",
      dataIndex: "patientStatus",
      valueType: "select",
      valueEnum: Object.fromEntries(
        [
          "Signup initiated",
          "Signed up",
          "Enrolled",
          "Device received",
          "Active",
          "Inactive",
          "Facing issue",
          "Denied services"
        ]?.map(key => [key, { text: key }])
      )
    },
    {
      title: "Billable Status",
      dataIndex: "billableStatus",
      onFilter: true,
      valueType: "select",
      valueEnum: {
        Yes: { text: "Yes" },
        No: { text: "No" }
      }
    },
    {
      title: "Marketing Engagement Status (Yes/No)",
      dataIndex: "marketingStatus",
      onFilter: true,
      valueType: "select",
      valueEnum: {
        Yes: { text: "Yes" },
        No: { text: "No" }
      }
    }
  ]

  useEffect(() => {
    if (selectedOrg?.id) dispatch(getLastReadingTakenAnalysisReport(selectedOrg.id))
  }, [selectedOrg])

  const transformOrgData = orgData => {
    if (!orgData?.length || orgData?.length === 0) return []
    return orgData?.map((opt, index) => {
      const data = {}
      data.id = opt?.id
      data.userViewId = opt?.userViewId
      data.key = index
      data.organization = opt?.orgName
      data.name = opt?.fullName
      data.ccm = opt?.careplan ? "True" : "False"
      data.rpm = opt?.treatmentplan ? "True" : "False"
      data.pcm = opt?.pcmplan ? "True" : "False"
      data.rtm = opt?.rtmplan ? "True" : "False"
      data.latestReadingDate = opt?.latestReadingDate
      data.lastReadingTime = opt?.latestReadingDate
      data.marketingStatus = opt?.marketingStatus ? "Yes" : "No"
      data.email = opt?.email
      data.mobileNumber = opt?.contactNumber ? formatPhoneNumber(opt?.contactNumber) : null
      data.billableStatus = opt?.billingStatus ? "Yes" : "No"
      data.equipmentStatus = opt?.equipmentStatus
      data.patientStatus = opt?.patientStatus
      return data
    })
  }

  useEffect(() => {
    const data = readingAnalysis?.length ? transformOrgData(readingAnalysis) : []
    setTableData(data)
    setBackupData(data)
  }, [readingAnalysis])

  const tableReq = params => {
    // @ts-expect-error
    delete params.current
    // @ts-expect-error
    delete params.pageSize

    const keys = Object.keys(params)
    if (keys?.length) {
      const filterData = backupData.filter(item => {
        return keys.every(key => {
          const itemValue = item[key]
          const paramValue = params[key] || ""
          if (itemValue === undefined || itemValue === null) {
            return false
          }
          const itemString = String(itemValue).toLowerCase()
          const paramString = String(paramValue).toLowerCase()
          if (key === "patientStatus") {
            return itemString === paramString
          }
          return paramString === "" || itemString.includes(paramString)
        })
      })
      setTableData(filterData)
    } else setTableData(backupData)
  }

  return (
    <>
      <Card>
        <div className="flex items-center justify-between flex-wrap gap-4">
          <h4 className="text-xl">Last Reading Analysis</h4>
          <SelectOrgDropdown
            size="large"
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
          />
        </div>
      </Card>

      <ProTable
        id="orgListProTable"
        className="orgTable"
        columns={proColumns}
        dataSource={tableData}
        loading={orgLoading || readingLoading}
        request={tableReq}
        columnsState={{
          persistenceKey: "pro-table-org-list-columns",
          persistenceType: "localStorage"
        }}
        search={
          orgLoading || readingLoading
            ? false
            : {
                searchText: "Find",
                labelWidth: "auto",
                showHiddenNum: true
              }
        }
        rowKey="key"
        options={{
          density: true,
          reload: false,
          fullScreen: true
        }}
        style={{ paddingTop: "30px" }}
        scroll={{ x: "max-content" }}
      />
    </>
  )
}

export default LastReadingAnalysis
