import {
  GET_RPM_FAIL,
  GET_RPM_REQUEST,
  GET_RPM_SUCCESS,
  POST_RPM_USER_FAIL,
  POST_RPM_USER_REQUEST,
  POST_RPM_USER_SUCCESS,
  GET_RPM_CAREPLAN_REQUEST,
  GET_RPM_CAREPLAN_SUCCESS,
  GET_RPM_CAREPLAN_FAIL,
  GET_RPM_FOLLOW_UP_REQUIEST,
  GET_RPM_FOLLOW_UP_SUCCESS,
  GET_RPM_FOLLOW_UP_FAIL,
  POST_RPM_TRIAGE_REQUEST,
  POST_RPM_TRIAGE_SUCCESS,
  POST_RPM_TRIAGE_FAIL,
  GET_RPM_DEVICE_REQUEST,
  GET_RPM_DEVICE_SUCCESS,
  GET_RPM_DEVICE_FAIL,
  POST_RPM_CLEAR_TRIAGE_REQUEST,
  POST_RPM_CLEAR_TRIAGE_SUCCESS,
  POST_RPM_CLEAR_TRIAGE_FAIL,
  GET_RPM_TRIAGE_DATA_REQUEST,
  GET_RPM_TRIAGE_DATA_SUCCESS,
  GET_RPM_TRIAGE_DATA_FAIL,
  POST_CRITICAL_READING_SNOOZE_REQUEST,
  POST_CRITICAL_READING_SNOOZE_SUCCESS,
  POST_CRITICAL_READING_SNOOZE_FAIL,
  ADD_CRITICAL_PATIENT_REQUEST,
  ADD_CRITICAL_PATIENT_SUCCESS,
  ADD_CRITICAL_PATIENT_FAIL
} from "../types/rpmConstants"
import { axiosGet, axiosPost } from "../axios"
import { userLogoutAction } from "./userActions"
import moment from "moment"
import packageJson from "../../../package.json"

export const getrpmDataActions = (orgId) =>
  async dispatch => {
    try {
      dispatch({
        type: GET_RPM_REQUEST
      })
      const userInfo = localStorage.getItem("userInfo")
      if (userInfo && orgId) {
        const caretakerId = JSON.parse(userInfo)?.id
        const { data } = await axiosGet(`/api/cs/patients/treatmentplan/caretaker/${caretakerId}/org/${orgId}/fetch`)
        dispatch({
          type: GET_RPM_SUCCESS,
          payload: data.details
        })
        return data
      }
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: GET_RPM_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

export const getTreatmentPlans = (id, range) => async dispatch => {
  try {
    dispatch({
      type: "GET_TREATMENT_PLAN_REQUEST"
    })
    const { data } = await axiosPost(`/api/rpm/readings/treatmentplan/${id}/archive`, range)

    dispatch({
      type: "GET_TREATMENT_PLAN_SUCCESS",
      payload: data.details
    })
    return data.details
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: "GET_TREATMENT_PLAN_FAIL",
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const updateRPMUserActions = (userId, formInputs, planId, rpmActiveDate) => async dispatch => {
  try {
    dispatch({
      type: POST_RPM_USER_REQUEST
    })

    const formattedDate = rpmActiveDate 
      ? moment(rpmActiveDate).format('YYYY-MM-DDTHH:mm:ss.SSS')
      : moment().format('YYYY-MM-DDTHH:mm:ss.SSS');

    // console.log('formInputs from action',formInputs);
    // debugger
    const { data } = await axiosPost(`/api/rpm/readings/treatmentplan/${userId}/update/${planId}`, {
      planDate: formattedDate,
      plan: formInputs
    })
    dispatch({
      type: POST_RPM_USER_SUCCESS,
      payload: data
    })
    // window.location.href = '/rpm/patients';
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_RPM_USER_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

export const postRPMUserActions = (userId, formInputs) => async dispatch => {
  try {
    dispatch({
      type: POST_RPM_USER_REQUEST
    })

    // console.log('formInputs from action',formInputs);
    // debugger
    const { data } = await axiosPost(`/api/rpm/readings/treatmentplan/${userId}/create`, {
      planDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      plan: formInputs
    })
    dispatch({
      type: POST_RPM_USER_SUCCESS,
      payload: data
    })
    // window.location.href = '/rpm/patients';
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_RPM_USER_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

export const getrpmCareplanDataActions = (userId, year) => async dispatch => {
  try {
    dispatch({
      type: GET_RPM_CAREPLAN_REQUEST
    })

    const { data } = await axiosGet(`/api/rpm/readings/treatmentplan/${userId}/fetch`)

    dispatch({
      type: GET_RPM_CAREPLAN_SUCCESS,
      payload: data?.details // also getting careplanDetails
    })
    return data?.details
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_RPM_CAREPLAN_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getUsersForRpmFollowUpAction = (month, year, orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_RPM_FOLLOW_UP_REQUIEST
    })
    const { data } = await axiosGet(
      `/caretaker/v1/getUserForRPMFollowUpPlan?month=${month}&year=${year}&orgId=${orgId}`
    )
    dispatch({
      type: GET_RPM_FOLLOW_UP_SUCCESS,
      payload: data.result
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_RPM_FOLLOW_UP_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postRpmTriageAction = (userId, report) => async dispatch => {
  try {
    dispatch({
      type: POST_RPM_TRIAGE_REQUEST
    })

    const { data } = await axiosPost(`/caretaker/v1/postUserTriageData`, { userId, report })
    dispatch({
      type: POST_RPM_TRIAGE_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    console.log("error", error)
    dispatch({
      type: POST_RPM_TRIAGE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

// oximeter create triage
export const postRpmOximeterTriageAction = (userId, report, readingIds, triageStartedAt, deviceType) => async dispatch => {

  try {
    dispatch({
      type: POST_RPM_TRIAGE_REQUEST
    })

    const { data } = await axiosPost(`/api/rpm2/readings/triage/report/${userId}/create`, { readingIds: readingIds, triageDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'), report, triageStartedAt: triageStartedAt, triageResolvedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'), deviceType })
    dispatch({
      type: POST_RPM_TRIAGE_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_RPM_TRIAGE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}
// bp machine create triage
export const postRpmBpTriageAction = (userId, report, readingIds, triageStartedAt, deviceType) => async dispatch => {
  try {
    dispatch({
      type: POST_RPM_TRIAGE_REQUEST
    })

    // const { data } = await axiosPost(`/admin/v1/postBPTriageData`, { userId, report, readingId })
    const { data } = await axiosPost(`/api/rpm2/readings/triage/report/${userId}/create`, { readingIds: readingIds, triageDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'), report, triageStartedAt: triageStartedAt, triageResolvedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'), deviceType })
    dispatch({
      type: POST_RPM_TRIAGE_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_RPM_TRIAGE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}
export const getrpmDeviceActions = () => async dispatch => {
  try {
    dispatch({
      type: GET_RPM_DEVICE_REQUEST
    })
    const { data } = await axiosGet("/api/cs/config/monitorTypes")
    dispatch({
      type: GET_RPM_DEVICE_SUCCESS,
      payload: data.details
    })
    localStorage.setItem("monitorTypes", JSON.stringify(data?.details))
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_RPM_DEVICE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}


export const postRpmClearTriageAction = (userId, message) => async dispatch => {
  try {
    dispatch({
      type: POST_RPM_CLEAR_TRIAGE_REQUEST
    })

    const { data } = await axiosPost(`/caretaker/v1/postClearTriageData`, {
      userId,
      message
    })
    dispatch({
      type: POST_RPM_CLEAR_TRIAGE_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_RPM_CLEAR_TRIAGE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

// clear triage 
export const postRpmOximeterClearTriageAction = ({userId, selectedIds, message, triageStartedAt, comment, deviceType}) => async dispatch => {
  try {
    dispatch({
      type: POST_RPM_CLEAR_TRIAGE_REQUEST
    })
    const { data } = await axiosPost(`/api/rpm2/readings/triage/report/${userId}/create`, {
      readingIds: selectedIds,
      triageDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      reason: message,
      triageStartedAt: triageStartedAt,
      triageResolvedAt: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      comment: comment,
      deviceType: deviceType
    })
    // await axiosPost(`/admin/v1/postCriticalStatus`,{userId, status:false});
    dispatch({
      type: POST_RPM_CLEAR_TRIAGE_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_RPM_CLEAR_TRIAGE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

// snooze
export const postCriticalReadingSnoozeAction = (patientid, selectedIds) => async dispatch => {
  try {
    dispatch({
      type: POST_CRITICAL_READING_SNOOZE_REQUEST
    })

    const { data } = await axiosPost(`/api/rpm/readings/critical/${patientid}/snooze`, {
      readingIds: selectedIds,
      snoozeDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
    })
    dispatch({
      type: POST_CRITICAL_READING_SNOOZE_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_CRITICAL_READING_SNOOZE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

export const postRpmBpClearTriageAction = (userId, message, readingId) => async dispatch => {
  try {
    dispatch({
      type: POST_RPM_CLEAR_TRIAGE_REQUEST
    })

    const { data } = await axiosPost(`/caretaker/v1/postClearBPTriageData`, {
      userId,
      message,
      readingId
    })
    // await axiosPost(`/admin/v1/postCriticalStatus`,{userId, status:false});
    dispatch({
      type: POST_RPM_CLEAR_TRIAGE_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_RPM_CLEAR_TRIAGE_FAIL,
      payload:
        error.response && error.response.data.detail ? error.response.data.detail : error.detail
    })
  }
}

export const getrpmTriageDataActions = (startDate, endDate, userId) => async dispatch => {
  try {
    dispatch({
      type: GET_RPM_TRIAGE_DATA_REQUEST
    })
    let data
    // if (type === "oxy") {
    data = await axiosPost(
      `/api/rpm/readings/triage/report/${userId}/fetch/10`, { startDate, endDate }
    )
    // } else {
    //   data = await axiosGet(
    //     `/admin/v1/getBPTriage?userId=${userId}&startDate=${start}&endDate=${end}`
    //   )
    // }
    // console.log('data',data)
    dispatch({
      type: GET_RPM_TRIAGE_DATA_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_RPM_TRIAGE_DATA_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const addCriticalPatientAction = (patientId, formData, userId) => async dispatch => {
  try {
    dispatch({
      type: ADD_CRITICAL_PATIENT_REQUEST
    })

    const config = {
      headers: {
        "fp-deviceType": "system",
        "fp-deviceName": "system",
        "fp-version": `${packageJson.version}`,
        "fp-takenById": userId,
        "fp-usertime" : moment().format('MM/DD/YYYY HH:mm:ss'),
        "fp-takenBy": "caretaker"
      }
    }

    const { data } = await axiosPost(`/api/rpm/readings/critical/${patientId}/create`, formData, config)
    dispatch({
      type: ADD_CRITICAL_PATIENT_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: ADD_CRITICAL_PATIENT_FAIL,
      payload: error.response && error?.response?.data?.details.message
    })
  }
}