export const mapDeviceType = type => {
  switch (type) {
    case "glucose machine":
      return "glucose machine"
    case "oximeter":
      return "oximeter"
    case "blood pressure machine":
      return "blood pressure machine"
    case "weight machine":
      return "weight scale"
    default:
      return type
  }
}
