import React, { useState, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import PlanView from "@fitpeo/fit-plan-viewer"
import { useDispatch, useSelector } from "react-redux"
import { convertCareplanToV2 } from "../../utils/careplanVersioning"
import { getDateOfService } from "../../utils/getDateOfService"
import {
  getPatientDiagnosticsData,
  getPatientInfo,
  getPatientMonitoringData
} from "../../redux/actions/patientAction"
import Loading from "../layouts/Loading/Loading"
import getFullName from "../../utils/getFullName"

const PatientPlanView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { orgId, id: patientId, orgName } = useParams()
  const [provider, setProvider] = useState([])
  const [practitioner, setPractitioner] = useState([])
  const location = useLocation()
  const plan = location?.state?.plan ?? {}
  const planDate = location?.state?.planDate
  const planType = location?.state?.planType
  const { addingBy, ...planWithoutAddingBy } = plan // for rpm only

  const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
  const { patientDiagnosticData } = useSelector(state => state.patientDiagnosticData)
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)

  const [monitorType, setMonitorTypes] = useState([])

  useEffect(() => {
    if (patientId && Object.keys(plan).length > 0) {
      dispatch(getPatientInfo({ patientId }))
    } else {
      navigate(`/${orgId}/${orgName}/patient-plans/${patientId}`)
    }
  }, [dispatch, patientId])

  useEffect(() => {
    if (Object.keys(plan).length > 0) {
      if (!plan?.planIcds) dispatch(getPatientDiagnosticsData({ patientId }))
      if (!plan?.providers) dispatch(getPatientMonitoringData({ patientId }))
    }
  }, [plan])

  useEffect(() => {
    let provider = []
    let practitioner = []

    if (plan?.providers) provider = plan?.providers
    else if (patientMonitoringData?.ccm?.[0]) {
      provider = patientMonitoringData?.ccm?.[0]?.providerId
        ? [
            getFullName(
              patientMonitoringData?.ccm?.[0]?.providerfirstName,
              patientMonitoringData?.ccm?.[0]?.providerMiddleName,
              patientMonitoringData?.ccm?.[0]?.providerMiddleName
            )
          ]
        : []
    }
    if (plan?.practitioners) practitioner = plan?.practitioners
    else if (patientMonitoringData?.ccm?.[0]) {
      practitioner = patientMonitoringData?.ccm?.[0]?.practitionerId
        ? [
            getFullName(
              patientMonitoringData?.ccm?.[0]?.practitionerfirstName,
              patientMonitoringData?.ccm?.[0]?.practitionerMiddleName,
              patientMonitoringData?.ccm?.[0]?.practitionerLastName
            )
          ]
        : []
    }
    if (plan?.provider) {
      provider = [
        getFullName(
          plan?.provider?.providerFirstName,
          plan?.provider?.providerMiddleName,
          plan?.provider?.providerLastName
        )
      ]
    }

    setProvider(provider)
    setPractitioner(practitioner)
  }, [plan, patientMonitoringData])

  useEffect(() => {
    // only for the rpm
    if (patientMonitoringData?.rpm && planType === "rpm") {
      let providers = []
      let practitioners = []
      patientMonitoringData?.rpm?.map(item => {
        if (item.providerId) {
          providers.push(
            getFullName(item?.providerfirstName, item?.providerMiddleName, item?.providerLastName)
          )
        }
        if (item.practitionerId) {
          practitioners.push(
            getFullName(
              item?.practitionerfirstName,
              item?.practitionerMiddleName,
              item?.practitionerLastName
            )
          )
        }
      })
      setProvider(providers)
      setPractitioner(practitioners)
    }
  }, [patientMonitoringData])
  useEffect(() => {
    let monitorTypes = []
    patientMonitoringData &&
      Object.keys(patientMonitoringData).map(item => {
        monitorTypes.push(item)
      })

    setMonitorTypes(monitorTypes)
  }, [patientMonitoringData])

  return patientLoading ? (
    <Loading />
  ) : (
    <>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-outline-primary btn-sm mx-2"
          onClick={() => {
            const urlMap = {
              rpm: `/${orgId}/${orgName}/rpm-view-treatement-print`,
              ccm: `/${orgId}/${orgName}/ccm-view-care-print`,
              pcm: `/${orgId}/${orgName}/pcm-view-care-print`
            }
            const headTitle = planType === "rpm" ? "View Treatement Plan" : ""
            const addedByPrint = planType === "rpm" ? plan?.addingBy : plan?.addedBy
            const url = urlMap[planType]
            const planToPrint = planType === "rpm" ? planWithoutAddingBy : convertCareplanToV2(plan)
            navigate(url, {
              state: {
                list: planToPrint,
                patientInfoDetail,
                id: patientId,
                provider,
                practitioner,
                plan: planToPrint,
                planIcds: plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode),
                addedBy: addedByPrint,
                planDate: planDate,
                monitorTypes: monitorType,
                title: headTitle
              }
            })
          }}>
          Print
        </button>
      </div>

      <PlanView
        type={planType}
        patientInfoDetail={patientInfoDetail}
        providers={provider}
        practitioners={practitioner}
        plan={planType === "rpm" ? planWithoutAddingBy : convertCareplanToV2(plan)}
        planIcds={plan?.planIcds ?? patientDiagnosticData?.map(({ icdCode }) => icdCode)}
        addedBy={planType === "rpm" ? plan?.addingBy : plan?.addedBy}
        planDate={planDate}
        serviceDate={getDateOfService(planDate)}
      />
    </>
  )
}

export default PatientPlanView
