import { axiosGet } from "../axios"
import { USER_SEARCH_REQUEST, USER_SEARCH_SUCCESS, USER_SEARCH_FAIL } from "../types/userSearchConstant"
import { userLogoutAction } from "./userActions"

export const searchUserWithTerm = (searchTerm) => async (dispatch) => {
  try {
    dispatch({ type: USER_SEARCH_REQUEST })

    const { data } = await axiosGet(`/api/cs/config/search/${searchTerm}`)
    dispatch({
      type: USER_SEARCH_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: USER_SEARCH_FAIL,
      payload: error.response?.data?.detail
    })
  }
}
