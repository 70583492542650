import React, { useState } from "react"
import { Button, Divider, Dropdown, Radio, Space, Typography, theme } from "antd"
import { RadioChangeEvent } from "antd"
import { CloseOutlined, DownOutlined } from "@ant-design/icons"
import { useSelector } from "react-redux"

const { useToken } = theme

const OnBoardingStageDropdown = props => {
  const {
    // caretakerAssigned,
    // setCaretakerAssigned,
    // onBoardingStageSelection,
    setOnboardingSelectedStage,
    options
  } = props
  const { token } = useToken()
  const { isDark } = useSelector(state => state.themeConfig)

  const [open, setOpen] = useState(false)
  const [values, setValues] = useState()
  const [onboardingStageState, setOnboardingStageState] = useState()

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
    // width: "140%"
  }

  const handleApply = () => {
    setOpen(false)
    setOnboardingStageState(values)
    setOnboardingSelectedStage(values)
  }

  const handleResetFilter = () => {
    setOnboardingStageState("")
    setValues("")
    setOnboardingSelectedStage("")
  }

  const onOpenChange = open => {
    if (!open) {
      setValues(onboardingStageState)
    }
    setOpen(open)
  }

  const onBoardingStageOptionChange = e => {
    setValues(e.target.value)
  }
  return (
    <>
      <Dropdown
        open={open}
        onOpenChange={onOpenChange}
        destroyPopupOnHide
        menu={{ items: [] }}
        trigger={["click"]}
        className="flex-1 bg-[#fff]"
        dropdownRender={_ => (
          <div style={contentStyle}>
            <div className="pt-2 px-4 mr-8">
              <Radio.Group onChange={onBoardingStageOptionChange} value={values}>
                <Space direction="vertical">
                  {Object.values(options ?? {})?.map(val => (
                    <Radio key={val} value={val} className="custom-radio">
                      {val}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
            <Divider className="my-3" />

            <div className="px-8 pb-4 flex justify-center">
              <Button type="primary" onClick={handleApply}>
                Apply Now
              </Button>
            </div>
          </div>
        )}>
        <Button
          style={{
            // minWidth: 150,
            height: "inherit"
          }}>
          <div className="flex items-center justify-center gap-2">
            {(onboardingStageState?.length && (
              <>
                <Typography.Link>Onboarding Stage : {onboardingStageState}</Typography.Link>
                <Typography.Link>
                  <DownOutlined />
                </Typography.Link>
              </>
            )) || (
              <>
                Onboarding Stage
                <DownOutlined />
              </>
            )}
          </div>
        </Button>
      </Dropdown>
      <Space.Compact
        className={`border card ${isDark ? "dark" : "light"}`}
        onClick={handleResetFilter}
        style={{ borderWidth: 1 }}>
        <span className="secondary px-4 pt-2">
          <CloseOutlined />
        </span>
      </Space.Compact>
    </>
  )
}

export default OnBoardingStageDropdown
