import React, { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import moment from "moment"
import { Button, Table, Tooltip, Card, DatePicker } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { EditOutlined, EyeOutlined, HistoryOutlined, LinkOutlined, PlusOutlined } from "@ant-design/icons"
import { getCcmArchivePlansActions, getccmCareplanDataActions } from "../../redux/actions/ccmActions"
import { getPatientCaretaker, getPatientDiagnosticsData, getPatientInfo, getPatientMonitoringData } from "../../redux/actions/patientAction"
import PatientPlanHeader from "./PatientPlanHeader"
import Loading from "../layouts/Loading/Loading"
import { getrpmCareplanDataActions } from "../../redux/actions/rpmAction"
import { USA_DATE_FORMAT } from "../../utils/usaDateFormat"
import { getpcmCareplanDataActions } from "../../redux/actions/pcmActions"
import { getrpmDeviceActions as getMonitorTypes } from "../../redux/actions/rpmAction"

const isInCurrentMonth = date => moment(date).isSame(new Date(), "month")
const prevMonthStart = moment().subtract(1, "month").startOf("month")
const prevMonthEnd = moment().subtract(1, "month").endOf("month")
const isInPrevMonth = date => moment(date).isBetween(prevMonthStart, prevMonthEnd)

const PlanType = {
  CCMCarePlan: "CCM Care Plan",
  PCMCarePlan: "PCM Care Plan",
  RPMTreatmentPlan: "Treatment Plan"
}

// helper methods
const getDaysDifference = (date) => {
  if (!date) return 0;
  const creationDate = new Date(date);
  const today = new Date();
  const diffTime = Math.abs(today - creationDate);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const isPlanExpired = (date) => {
  return getDaysDifference(date) > 365;
};

const isInFollowUpPeriod = (date) => {
  const days = getDaysDifference(date);
  return days >= 365 && days <= 730; // Between 1 and 2 years
};

const PatientPlans = () => {
  const [ccmData, setCcmData] = useState([])
  const [RpmData, setRpmData] = useState([])
  const [PcmData, setPcmData] = useState([])

  const [ccmMonitorId, setCcmMonitorId] = useState(null)
  const [pcmMonitorId, setPcmMonitorId] = useState(null)
  const [entirePlanHistory, setEntirePlanHistory] = useState([])
  const [entirePlanHistoryIsLoading, setEntirePlanHistoryIsLoading] = useState(true)
  const [moniteringType, setMonitoringType] = useState([])
  const [ccmProvider, setCcmProvider] = useState([])
  const [ccmPractitioner, setCcmPractitioner] = useState([])
  const [pcmProvider, setPcmProvider] = useState([])
  const [pcmPractitioner, setPcmPractitioner] = useState([])

  const { id, orgId, orgName } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const { loading: ccmLoading } = useSelector(state => state.ccmPatientCarePlan)
  //const { loading: patientLoading, patientInfoDetail } = useSelector(state => state.patientInfo)
  const { loading: rpmLoading } = useSelector(state => state.rpmPatientCarePlan)
  const { loading: pcmLoading } = useSelector(state => state.getPcmCarePlan)
  const { loading: caretakerLoading, patientCaretaker } = useSelector(
    state => state.patientCaretaker
  )
  const { patientMonitoringData } = useSelector(state => state.patientMonitoringData)
  const { loading: DiagnosticsLoading, patientDiagnosticData } = useSelector(
    state => state.patientDiagnosticData
  )
  let patientInfo = location?.state?.patientInfo
  let monitoringData = location?.state?.monitoringData
  let monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))

  const checkMonitorType = async () => {
    if (!monitorTypes?.length) {
      await dispatch(getMonitorTypes())
      monitorTypes = JSON.parse(localStorage.getItem("monitorTypes"))
    }
    const data = monitorTypes.find(value => value.name === "ccm")
    setCcmMonitorId(data?.programs[0]?.id)
    const pcmData1 = monitorTypes.find(value => value.name === "pcm")
    setPcmMonitorId(pcmData1?.programs[0]?.id)
  }

  useEffect(() => {
    checkMonitorType()
  }, [monitorTypes])

  useEffect(() => {
    Object.keys(monitoringData ?? {}).map((key) => {
      if (key === "ccm") {
        monitoringData[key]?.forEach((p) => {
          if (p?.providerId) {
            setCcmProvider([...ccmProvider, `${p?.providerfirstName} ${p?.providerMiddleName ? p?.providerMiddleName : ""} ${p?.providerLastName}`])
          } else if (p?.practitionerId) {
            setCcmPractitioner([...ccmPractitioner, `${p?.practitionerfirstName} ${p?.practitionerMiddleName ? p?.practitionerMiddleName : ""} ${p?.practitionerLastName}`])
          } else {
            setCcmProvider([])
            setCcmPractitioner([])
          }
        })
      }

      if (key === "pcm") {
        monitoringData[key]?.forEach((p) => {
          if (p?.providerId) {
            setPcmProvider([...pcmProvider, `${p?.providerfirstName} ${p?.providerMiddleName ? p?.providerMiddleName : ""} ${p?.providerLastName}`])
          } else if (p?.practitionerId) {
            setPcmPractitioner([...pcmPractitioner, `${p?.practitionerfirstName} ${p?.practitionerMiddleName ? p?.practitionerMiddleName : ""} ${p?.practitionerLastName}`])
          } else {
            setPcmProvider([])
            setPcmPractitioner([])
          }
        })
      }
    })
  }, [monitoringData])

  useEffect(() => {
    setMonitoringType(Object.keys(monitoringData ?? {}))

    if (id) {
      dispatch(getccmCareplanDataActions({ patientId: id })).then((response) => {
        if (response && response.details) {
          const ccmRes = response.details.map((plan) => ({ ...plan, "planType": PlanType.CCMCarePlan }));
          setCcmData(ccmRes);
        }
      })

      dispatch(getpcmCareplanDataActions(id)).then((res) => {
        if (res && res.details) {
          const pcmRes = res?.details?.map((plan) => ({ ...(plan ?? []), "planType": PlanType.PCMCarePlan }))
          setPcmData(pcmRes);
        }
      })

      dispatch(getrpmCareplanDataActions(id)).then(res => {
        const rpmRes = res?.map((plan) => ({ ...(plan ?? []), "planType": PlanType.RPMTreatmentPlan }))
        setRpmData(rpmRes);
      })

      if (!patientInfo && !patientInfo?.length) {
        dispatch(getPatientInfo({ patientId: id }))
      }
    }
  }, [dispatch, id])

  useEffect(() => {
    if (!ccmLoading && !rpmLoading && !pcmLoading) {
      setEntirePlanHistory(getManupulatedData([...(ccmData ?? []), ...(RpmData ?? []), ...(PcmData ?? [])]))
      setEntirePlanHistoryIsLoading(false)
    } else if (setEntirePlanHistory?.length) {
      setEntirePlanHistoryIsLoading(true)
    }
  }, [ccmLoading, rpmLoading, pcmLoading, ccmData, RpmData, PcmData])

  const getManupulatedData = data => {
    return data?.map(
      (
        {
          firstName,
          middleName,
          lastName,
          id,
          planType,
          planDate,
          dob,
          plan
        },
        index
      ) => ({
        key: index + 1,
        sNo: index + 1,
        planId: id,
        planDate,
        planType,
        fullName: `${patientInfo?.firstName} ${patientInfo?.middleName ? patientInfo?.middleName : ''} ${patientInfo?.lastName}`,
        patientNameObj: { firstName: patientInfo?.firstName, middleName: patientInfo?.middleName, lastName: patientInfo?.lastName },
        dob: patientInfo?.dob,
        ccmProvider: ccmProvider && ccmProvider.length ? ccmProvider : [],
        ccmPractitioner: ccmPractitioner && ccmPractitioner.length ? ccmPractitioner : [],
        pcmProvider: pcmProvider && pcmProvider.length ? pcmProvider : [],
        pcmPractitioner: pcmPractitioner && pcmPractitioner.length ? pcmPractitioner : [],
        isSameMonthPlanCreated: isInCurrentMonth(planDate),
        plan
      })
    )
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Plan Date",
      dataIndex: "planDate",
      key: "planDate",
      render: planDate => <p>{moment(planDate).format(USA_DATE_FORMAT)}</p>,
      sorter: (a, b) => a.planDate.localeCompare(b.planDate)
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, { plan, planType }) => (
        <p className="capitalize">
          {(planType === PlanType.CCMCarePlan || planType === PlanType.PCMCarePlan) ?
            (plan?.addedBy ? `${plan?.addedBy?.name} (${plan?.addedBy?.userType})` : "-")
            :
            (plan?.addingBy ? `${plan?.addingBy?.name} (${plan?.addingBy?.userType})` : "-")
          }
        </p>
      )
    },
    {
      title: "Plan Type",
      dataIndex: "planType",
      key: "planType",
      render: planType => (
        <p className="capitalize">
          {planType}
        </p>
      ),
      sorter: (a, b) => a.planType.localeCompare(b.planType)
    },
    {
      title: "Action",
      render: ({ isSameMonthPlanCreated, ccmProvider, ccmPractitioner, pcmProvider, pcmPractitioner, patientNameObj, dob, planType, planDate, plan }) =>
        (planType === PlanType.CCMCarePlan) ? (
          <div className="flex gap-3 items-center">
            {!isSameMonthPlanCreated && (
              <Tooltip title="Create">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    navigate(`/${orgId}/${orgName}/ccm-questions`, {
                      state: { patientNameObj, id, provider: ccmProvider, practitioner: ccmPractitioner, ccmMonitorId, dob }
                    })
                  }
                />
              </Tooltip>
            )}
            {planDate && (
              <>
                <Tooltip title="View">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EyeOutlined />}
                    onClick={() => navigate(`/${orgId}/${orgName}/patient-plans/${id}/view`, {
                      state: { patientNameObj, id, dob, plan, planType:"ccm", planDate }
                    })}
                  />
                </Tooltip>
                {isInPrevMonth(planDate) && (
                  <Tooltip title="Follow Up">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<LinkOutlined />}
                      onClick={() => navigate(`/${orgId}/${orgName}/ccm-follow-up/${id}`)}
                    />
                  </Tooltip>
                )}
                {isSameMonthPlanCreated && (
                  <Tooltip title="Edit">
                    <Button
                      type="primary"
                      shape="circle"
                      disabled={!planDate}
                      icon={<EditOutlined />}
                      onClick={() => navigate(`/${orgId}/${orgName}/ccm-edit-care/${id}`)}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </div>
        ) : (planType === PlanType.PCMCarePlan) ? (
          <div className="flex gap-3 items-center">
            {!isSameMonthPlanCreated && (
              <Tooltip title="Create">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    navigate(`/${orgId}/${orgName}/pcm-questions`, {
                      state: { patientNameObj, id, provider: pcmProvider, practitioner: pcmPractitioner, pcmMonitorId, dob }
                    })
                  }
                />
              </Tooltip>
            )}
            {planDate && (
              <>
                <Tooltip title="View">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EyeOutlined />}
                    onClick={() => navigate(`/${orgId}/${orgName}/patient-plans/${id}/view`, {
                      state: { patientNameObj, id, dob, plan, planType:"pcm", planDate }
                    })}
                  />
                </Tooltip>
                {isInPrevMonth(planDate) && (
                  <Tooltip title="Follow Up">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<LinkOutlined />}
                      onClick={() => navigate(`/${orgId}/${orgName}/pcm-follow-up/${id}`)}
                    />
                  </Tooltip>
                )}
                {isSameMonthPlanCreated && (
                  <Tooltip title="Edit">
                    <Button
                      type="primary"
                      shape="circle"
                      disabled={!planDate}
                      icon={<EditOutlined />}
                      onClick={() => navigate(`/${orgId}/${orgName}/pcm-edit-care/${id}`)}
                    />
                  </Tooltip>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            <div className="flex items-center gap-2 flex-wrap">
              <Tooltip title="Create Plan">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={() => navigate(`/${orgId}/${orgName}/rpm-questions/${id}`, {
                    state: {
                      name: {
                        firstName: patientInfo.firstName,
                        middleName: patientInfo.middleName,
                        lastName: patientInfo.lastName,
                      },
                      dob: patientInfo.dob,
                    }
                  })}
                />
              </Tooltip>

              {planDate && planDate.length ? (
                <>
                  <Tooltip title="View Plan">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<EyeOutlined />}
                      onClick={() => navigate(`/${orgId}/${orgName}/patient-plans/${id}/view`, {
                        state: { patientNameObj, id, dob, plan, planType:"rpm", planDate }
                      })}
                    />
                  </Tooltip>
                  {isPlanExpired(planDate) ? null : (
                    <Tooltip title="Update Plan">
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => navigate(`/${orgId}/${orgName}/rpm-edit-treatement/${id}`)}
                      />
                    </Tooltip>
                  )}
                  {isInFollowUpPeriod(planDate) ? (
                    <Tooltip title="Follow Up">
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<LinkOutlined />}
                        onClick={() => navigate(`/${orgId}/${orgName}/rpm-follow-up/${id}`, {
                          state: {
                            name: {
                              firstName: patientInfo.firstName,
                              middleName: patientInfo.middleName,
                              lastName: patientInfo.lastName,
                            },
                            dob: patientInfo.dob,
                          }
                        })}
                      />
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
            </div>
          </>
        )
    }
  ]

  return (
    <div>
      {caretakerLoading ? (
        <Loading />
      ) : (
        <>
          {
            <>
              <PatientPlanHeader
                patientInfoDetail={patientInfo}
                id={id}
                requestFrom={'plan-history'}
                moniteringType={moniteringType}
              // patientCaretaker={patientCaretaker}
              // patientMonitoringData={patientMonitoringData}
              // carePlanData={carePlanData}
              />
              <Card
                title={<h4 className="text-xl">Plan History</h4>}
              >
                <Table
                  size="small"
                  columns={columns}
                  dataSource={entirePlanHistory?.sort((a, b) => moment(b.planDate).valueOf() - moment(a.planDate).valueOf())}
                  scroll={{ x: "max-content" }}
                  loading={entirePlanHistoryIsLoading}
                />
              </Card>
            </>
          }
        </>)}
    </div>
  )
}

export default PatientPlans
