import { axiosLengthyGet } from "../axiosLargeFileGetRequest"
import { axiosGet, axiosPost, axiosDelete } from "../axios"

import {
  ADD_MANUAL_TIME_FAIL,
  ADD_MANUAL_TIME_REQUEST,
  ADD_MANUAL_TIME_SUCCESS,
  ADD_PATIENT_FAIL,
  ADD_PATIENT_REQUEST,
  ADD_PATIENT_SUCCESS,
  CHRONIC_TYPE_FAIL,
  CHRONIC_TYPE_REQUEST,
  CHRONIC_TYPE_SUCCESS,
  CRITICAL_STATUS_FAIL,
  CRITICAL_STATUS_REQUEST,
  CRITICAL_STATUS_SUCCESS,
  GET_CHRONIC_CONDITION_FAIL,
  GET_CHRONIC_CONDITION_REQUEST,
  GET_CHRONIC_CONDITION_SUCCESS,
  GET_MANUAL_TIME_FAIL,
  GET_MANUAL_TIME_REQUEST,
  GET_MANUAL_TIME_SUCCESS,
  GET_MANUAL_TIME_EDIT_HISTORY_REQUEST,
  GET_MANUAL_TIME_EDIT_HISTORY_SUCCESS,
  GET_MANUAL_TIME_EDIT_HISTORY_FAIL,
  GET_REPORT_DATA_FAIL,
  GET_REPORT_DATA_REQUEST,
  GET_REPORT_DATA_SUCCESS,
  PATIENT_BP_FAIL,
  PATIENT_BP_REQUEST,
  PATIENT_BP_SUCCESS,
  PATIENT_HEALTH_KPI_FAIL,
  PATIENT_HEALTH_KPI_REQUEST,
  PATIENT_HEALTH_KPI_SUCCESS,
  PATIENT_INFO_FAIL,
  PATIENT_INFO_REQUEST,
  PATIENT_INFO_SUCCESS,
  PATIENT_OXY_FAIL,
  PATIENT_OXY_REQUEST,
  PATIENT_OXY_SUCCESS,
  PATIENT_GLUCOSE_FAIL,
  PATIENT_GLUCOSE_REQUEST,
  PATIENT_GLUCOSE_SUCCESS,
  PATIENT_READING_FAIL,
  PATIENT_READING_REQUEST,
  PATIENT_READING_SUCCESS,
  PATIENT_WM_FAIL,
  PATIENT_WM_REQUEST,
  PATIENT_WM_SUCCESS,
  POST_CHRONIC_DATA_FAIL,
  POST_CHRONIC_DATA_REQUEST,
  POST_CHRONIC_DATA_SUCCESS,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_LANDLINE_FAIL,
  UPDATE_PATIENT_LANDLINE_REQUEST,
  UPDATE_PATIENT_LANDLINE_SUCCESS,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
  POST_CONCERN_REQUEST,
  POST_CONCERN_SUCCESS,
  POST_CONCERN_FAIL,
  GET_CONCERN_REQUEST,
  GET_CONCERN_SUCCESS,
  GET_CONCERN_FAIL,
  GET_TRIAGE_CRITICAL_PATIENT_REQUEST,
  GET_TRIAGE_CRITICAL_PATIENT_SUCCESS,
  GET_TRIAGE_CRITICAL_PATIENT_FAIL,
  PATIENT_MONITORING_REQUEST,
  PATIENT_MONITORING_SUCCESS,
  PATIENT_MONITORING_FAIL,
  PATIENT_DIAGNOSTIC_REQUEST,
  PATIENT_DIAGNOSTIC_SUCCESS,
  PATIENT_DIAGNOSTIC_FAIL,
  POST_PATIENT_DIAGNOSTIC_REQUEST,
  POST_PATIENT_DIAGNOSTIC_SUCCESS,
  POST_PATIENT_DIAGNOSTIC_FAIL,
  PATIENT_VITALS_REQUEST,
  PATIENT_VITALS_SUCCESS,
  PATIENT_VITALS_FAIL,
  POST_PATIENT_VITALS_REQUEST,
  POST_PATIENT_VITALS_SUCCESS,
  POST_PATIENT_VITALS_FAIL,
  POST_MONITORING_REQUEST,
  POST_MONITORING_SUCCESS,
  POST_MONITORING_FAIL,
  DELETE_MONITORING_REQUEST,
  DELETE_MONITORING_SUCCESS,
  DELETE_MONITORING_FAIL,
  GET_PATIENT_CARETAKER_REQUEST,
  GET_PATIENT_CARETAKER_SUCCESS,
  GET_PATIENT_CARETAKER_FAIL,
  GET_PATIENT_MANUAL_LOG_REQUEST,
  GET_PATIENT_MANUAL_LOG_SUCCESS,
  GET_PATIENT_MANUAL_LOG_FAIL,
  GET_PATIENT_INSURANCE_REQUEST,
  GET_PATIENT_INSURANCE_SUCCESS,
  GET_PATIENT_INSURANCE_FAIL,
  PATIENT_CRITICAL_DEVICES_REQUEST,
  PATIENT_CRITICAL_DEVICES_SUCCESS,
  PATIENT_CRITICAL_DEVICES_FAIL,
  POST_PATIENT_FACILITY_ADDRESS_SUCCESS,
  POST_PATIENT_FACILITY_ADDRESS_REQUEST,
  POST_PATIENT_FACILITY_ADDRESS_FAIL,
  UPDATE_PATIENT_INSURANCE_REQUEST,
  UPDATE_PATIENT_INSURANCE_SUCCESS,
  UPDATE_PATIENT_INSURANCE_FAIL,
  POST_PATIENT_INSURANCE_FAIL,
  POST_PATIENT_INSURANCE_SUCCESS,
  POST_PATIENT_INSURANCE_REQUEST,
  GET_PATIENT_MENU_CONFIG_REQUEST,
  GET_PATIENT_MENU_CONFIG_SUCCESS,
  GET_PATIENT_MENU_CONFIG_FAIL,
  GET_PATIENT_OPTOUT_REQUEST,
  GET_PATIENT_OPTOUT_SUCCESS,
  GET_PATIENT_OPTOUT_FAIL,
  POST_PATIENT_OPTOUT_REQUEST,
  POST_PATIENT_OPTOUT_SUCCESS,
  POST_PATIENT_OPTOUT_FAIL,
  PATIENT_DETAILS_VALIDATE_REQUEST,
  PATIENT_DETAILS_VALIDATE_SUCCESS,
  PATIENT_DETAILS_VALIDATE_FAIL,
  PATIENT_DETAILS_VALIDATE_RESET,
  GET_TRIAGE_FALSE_REPORT_REQUEST,
  GET_TRIAGE_FALSE_REPORT_SUCCESS,
  GET_TRIAGE_FALSE_REPORT_FAIL,
  POST_OUTPATIENT_ADDRESS_REQUEST,
  POST_OUTPATIENT_ADDRESS_SUCCESS,
  POST_OUTPATIENT_ADDRESS_FAIL,
  GET_PATIENT_ADDRESS_REQUEST,
  GET_PATIENT_ADDRESS_SUCCESS,
  GET_PATIENT_ADDRESS_FAIL,
  PATIENT_MONITORING_RESET,
  GET_FACILITY_USER_ADDRESS_REQUEST,
  GET_FACILITY_USER_ADDRESS_SUCCESS,
  GET_FACILITY_USER_ADDRESS_FAIL,
  GET_FACILITY_USER_ADDRESS_RESET
} from "../types/PatientConstant"
import { userLogoutAction } from "./userActions"
import moment from "moment"

export const postAddPatient = (formData, orgId) => async dispatch => {
  try {
    if (!orgId) return
    dispatch({
      type: ADD_PATIENT_REQUEST
    })
    const { data } = await axiosPost(
      `/api/cs/patients/${orgId}/create`,
      formData
    )

    dispatch({
      type: ADD_PATIENT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    //console.log("error", Object.values(error?.response?.details)?.[0])
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: ADD_PATIENT_FAIL,
      payload: Object?.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const getPatientHealthKpi = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_HEALTH_KPI_REQUEST
    })

    const { data } = await axiosGet(
      `v1/getPatientDetailsKPIForProviderDB?providerId=${patientId.patientId}`
    )

    dispatch({
      type: PATIENT_HEALTH_KPI_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_HEALTH_KPI_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getWmDetails = fetchData => async dispatch => {
  const { programId, patientId, startDate, endDate } = fetchData
  try {
    dispatch({
      type: PATIENT_WM_REQUEST
    })

    const { data } = await axiosPost(`/api/rpm/readings/${programId}/wm/${patientId}/fetch/100`, {
      startDate,
      endDate
    })

    dispatch({
      type: PATIENT_WM_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_WM_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getBpDetails = fetchData => async dispatch => {
  const { programId, patientId, startDate, endDate } = fetchData
  try {
    dispatch({
      type: PATIENT_BP_REQUEST
    })

    const { data } = await axiosPost(`/api/rpm/readings/${programId}/bp/${patientId}/fetch/100`, {
      startDate,
      endDate
    })
    dispatch({
      type: PATIENT_BP_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_BP_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const getOxyDetails = fetchData => async dispatch => {
  const { programId, patientId, startDate, endDate } = fetchData
  try {
    dispatch({
      type: PATIENT_OXY_REQUEST
    })

    const { data } = await axiosPost(
      `/api/rpm/readings/${programId}/oximeter/${patientId}/fetch/100`,
      {
        startDate,
        endDate
      }
    )

    dispatch({
      type: PATIENT_OXY_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_OXY_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getGlucoseDetails = fetchData => async dispatch => {
  const { programId, patientId, startDate, endDate } = fetchData
  try {
    dispatch({
      type: PATIENT_GLUCOSE_REQUEST
    })

    const { data } = await axiosPost(
      `/api/rpm/readings/${programId}/glucose/${patientId}/fetch/100`,
      {
        startDate,
        endDate
      }
    )

    dispatch({
      type: PATIENT_GLUCOSE_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_GLUCOSE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getLatestReadings = (patientId, month, year) => async dispatch => {
  try {
    dispatch({
      type: PATIENT_READING_REQUEST
    })
    let payload
    if (month !== undefined && year !== undefined) {
      payload = { month, year }
    } else {
      payload = {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      }
    }
    // const payload = { month: new Date().getMonth() + 1, year: new Date().getFullYear(), userId: patientId.patientId };
    const { data } = await axiosGet(
      `/api/rpm/readings/count/${patientId}/${payload.month}/${payload.year}`
    )

    dispatch({
      type: PATIENT_READING_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_READING_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getPatientInfo = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_INFO_REQUEST
    })

    // const { data } = await axiosGet(`admin/v1/getUserProfile?memberId=${patientId.patientId}`);
    // const { data } = await axiosGet(`/admin/v2/getUserProfile?memberId=${patientId.patientId}`);
    const { data } = await axiosGet(`/api/cs/patients/${patientId.patientId}/profile`)

    dispatch({
      type: PATIENT_INFO_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_INFO_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const getPatientInsurance = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_INSURANCE_REQUEST
    })

    // const { data } = await axiosGet(`admin/v1/getUserProfile?memberId=${patientId.patientId}`);
    // const { data } = await axiosGet(`/admin/v2/getUserProfile?memberId=${patientId.patientId}`);
    const { data } = await axiosGet(`/api/cs/patients/insurance/${patientId}/fetch`)

    dispatch({
      type: GET_PATIENT_INSURANCE_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_INSURANCE_FAIL,
      payload: error.response && error?.response?.data?.details?.message
    })
  }
}
// POST ADD INSURANCE 
export const postPatientInsuranceAction =
  ({ formData, patientId }) => async dispatch => {
    try {
      dispatch({
        type: POST_PATIENT_INSURANCE_REQUEST
      })
      const updatedFormData = {
        insuranceDetails: formData,
        date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
      }
      const { data } = await axiosPost(`/api/cs2/patients/insurance/${patientId}/create`, updatedFormData)
      dispatch({
        type: POST_PATIENT_INSURANCE_SUCCESS,
        payload: data.details
      })
      return data
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: POST_PATIENT_INSURANCE_FAIL,
        payload: error.response && error?.response?.data?.details.message
      })
    }
  }

export const updatePatientInsuranceAction = (patientId, formData) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_PATIENT_INSURANCE_REQUEST
    })
    const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
    const { data } = await axiosPost(`/api/cs2/patients/insurance/${patientId}/update`, updatedFormData)
    dispatch({
      type: UPDATE_PATIENT_INSURANCE_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_PATIENT_INSURANCE_FAIL,
      payload: error.response && error?.response?.data?.details?.message
    })
  }
}

export const deletePatientInsuranceAction = (patientId, insuranceId) => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_INSURANCE_REQUEST
    })

    const { data } = await axiosDelete(`/api/cs/patients/insurance/${patientId}/delete/${insuranceId}`)

    dispatch({
      type: GET_PATIENT_INSURANCE_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_INSURANCE_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

// get patient's critical device(s) in v2
export const getPatientCriticalDevices = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_CRITICAL_DEVICES_REQUEST
    })

    const { data } = await axiosGet(`/api/rpm/readings/critical/${patientId}/fetch`)

    dispatch({
      type: PATIENT_CRITICAL_DEVICES_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_CRITICAL_DEVICES_FAIL,
      payload: error?.response && error?.response?.data?.error
    })
  }
}

export const getTriageFalseReport = (patientId, readingIds) => async dispatch => {
  try {
    dispatch({
      type: GET_TRIAGE_FALSE_REPORT_REQUEST
    })

    const { data } = await axiosPost(`/api/rpm2/readings/critical/${patientId}/fetch`, readingIds)
    dispatch({
      type: GET_TRIAGE_FALSE_REPORT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_TRIAGE_FALSE_REPORT_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

// POST ADD MANUAL TIME
export const AddManualTimeAction =
  (patientId, program, care, dateTime, duration, note) => async dispatch => {
    try {
      dispatch({
        type: ADD_MANUAL_TIME_REQUEST
      })
      const logDate = new Date()
      logDate.setTime(logDate.getTime() - logDate.getTimezoneOffset() * 60000)
      const dateAsString = logDate.toISOString().substr(0, 19)
      const formData = {
        program: [program],
        care,
        careTime: duration,
        careDate: dateTime,
        note,
        userDate: dateAsString
      }

      const { data } = await axiosPost(`/api/cs/patients/manualLog/${patientId}/create`, formData)
      dispatch({
        type: ADD_MANUAL_TIME_SUCCESS,
        payload: data.details
      })
      return data
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: ADD_MANUAL_TIME_FAIL,
        payload: error.response && error?.response?.data?.details.message
      })
    }
  }

export const updateManualTimeAction =
  (patientId, logId, program, care, dateTime, duration, note) => async dispatch => {
    try {
      dispatch({
        type: ADD_MANUAL_TIME_REQUEST
      })
      const logDate = new Date()
      logDate.setTime(logDate.getTime() - logDate.getTimezoneOffset() * 60000)
      const dateAsString = logDate.toISOString().substr(0, 19)
      const formData = {
        program: [program],
        care,
        careTime: duration.toString(),
        careDate: dateTime,
        note,
        userDate: dateAsString
      }

      console.log(formData)

      const { data } = await axiosPost(
        `/api/cs/patients/manualLog/${patientId}/update/${logId}`,
        formData
      )
      dispatch({
        type: ADD_MANUAL_TIME_SUCCESS,
        payload: data.details
      })
      return data
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: ADD_MANUAL_TIME_FAIL,
        payload: error.response && error?.response?.data?.details.message
      })
    }
  }

// GET MANUAL TIME DATA
export const getManualTimeAction = (patientId, startDate, endDate) => async dispatch => {
  try {
    dispatch({
      type: GET_MANUAL_TIME_REQUEST
    })

    const { data } = await axiosPost(`/api/cs/patients/manualLog/${patientId}/fetch/100`, {
      startDate,
      endDate
    })

    dispatch({
      type: GET_MANUAL_TIME_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_MANUAL_TIME_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

// GET MANUAL TIME EDIT HISTORY DATA
export const getManualTimeEditHistoryAction = (manualLogId) => async dispatch => {
  try {
    dispatch({
      type: GET_MANUAL_TIME_EDIT_HISTORY_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/manualLog/history/${manualLogId}/fetch`)
    dispatch({
      type: GET_MANUAL_TIME_EDIT_HISTORY_SUCCESS,
      payload: data?.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_MANUAL_TIME_EDIT_HISTORY_FAIL,
      payload:
      typeof error?.response?.data?.details === "object" &&
      Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const deleteManualTimeAction = (patientId, logId) => async dispatch => {
  try {
    dispatch({
      type: GET_MANUAL_TIME_REQUEST
    })

    const { data } = await axiosDelete(`/api/cs/patients/manualLog/${patientId}/delete/${logId}`)

    dispatch({
      type: GET_MANUAL_TIME_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_MANUAL_TIME_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

// GET MANUAL TIME DATA
export const getChronicConditionAction = patientId => async dispatch => {
  try {
    dispatch({
      type: GET_CHRONIC_CONDITION_REQUEST
    })

    const { data } = await axiosGet(`/caretaker/v1/getDiagnosticData?memberId=${patientId}`)

    dispatch({
      type: GET_CHRONIC_CONDITION_SUCCESS,
      payload: data.diagnosticData
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CHRONIC_CONDITION_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const criticalStatusAction = (criticalStatus, patientId, device, v1) => async dispatch => {
  try {
    dispatch({
      type: CRITICAL_STATUS_REQUEST
    })
    let data
    if (v1) {
      data = await axiosPost(`/caretaker/v1/postCriticalStatus`, {
        status: criticalStatus,
        userId: patientId
      })
    } else {
      data = await axiosPost(`/caretaker/v2/postCriticalStatus`, {
        status: criticalStatus,
        userId: patientId,
        device
      })
    }

    dispatch({
      type: CRITICAL_STATUS_SUCCESS,
      payload: data?.data
    })
    return data?.data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CRITICAL_STATUS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getChronicTypeListAction = memberId => async dispatch => {
  try {
    dispatch({
      type: CHRONIC_TYPE_REQUEST
    })

    const { data } = await axiosGet(`/caretaker/v1/getDiagnosticData?memberId=${memberId}`)

    dispatch({
      type: CHRONIC_TYPE_SUCCESS,
      payload: data
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: CHRONIC_TYPE_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postChronicConditionAction =
  (memberId, chronicCondition, chronicConditionsData) => async dispatch => {
    try {
      dispatch({
        type: POST_CHRONIC_DATA_REQUEST
      })

      const { data } = await axiosPost(`/caretaker/v1/postDiagnosticData`, {
        userId: memberId.toString(),
        diagnosticData: chronicCondition,
        ccmCareplanMetadata: chronicConditionsData
      })

      dispatch({
        type: POST_CHRONIC_DATA_SUCCESS,
        payload: data
      })
      dispatch(getChronicConditionAction(memberId))
      return data
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: POST_CHRONIC_DATA_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

// export const UpdatePatientAction =
//   ({
//     countryCode,
//     addressDetails,
//     userId,
//     firstName,
//     lastName,
//     middleName,
//     dob,
//     gender,
//     userName,
//     orgId,
//     moniterDetails,
//     primaryAdminId,
//     caretakerId,
//     vitalDetails,
//     insuranceNumber,
//     insuranceProvider,
//     diagnosticData,
//     ccmCareplanMetadata
//   }) =>
//   async dispatch => {
//     try {
//       dispatch({
//         type: UPDATE_PATIENT_REQUEST
//       })

//       const formData = {
//         countryCode,
//         addressDetails,
//         userId,
//         firstName,
//         lastName,
//         middleName,
//         dob,
//         gender,
//         userName,
//         orgId,
//         moniterDetails,
//         primaryAdminId,
//         caretakerId,
//         vitalDetails,
//         insuranceNumber,
//         insuranceProvider,
//         diagnosticData,
//         ccmCareplanMetadata
//       }

//       /* if (facilityAddress.address !== undefined && facilityAddress.address.length > 0) {
//                 formData.facilityAddress = facilityAddress;
//             } */

//       const { data } = await axiosPost(`/admin/v3/updatePatientProfile`, formData)
//       dispatch({
//         type: UPDATE_PATIENT_SUCCESS,
//         payload: JSON.stringify(data)
//       })
//       return data
//     } catch (error) {
//       console.log("error", error)
//       error?.response?.status === 401 && dispatch(userLogoutAction())
//       dispatch({
//         type: UPDATE_PATIENT_FAIL,
//         payload: error.response && error?.response?.data?.detail
//       })
//     }
//   }

export const UpdatePatientAction = formData => async dispatch => {
  try {
    dispatch({
      type: UPDATE_PATIENT_REQUEST
    })
    const updatedFormData = {
      ...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")
    }

    /* if (facilityAddress.address !== undefined && facilityAddress.address.length > 0) {
                formData.facilityAddress = facilityAddress;
            } */

    const { data } = await axiosPost(`/api/cs2/patients/${updatedFormData.id}/update`, updatedFormData)
    dispatch({
      type: UPDATE_PATIENT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_PATIENT_FAIL,
      payload: Object?.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const getOutPatientAddressAction = patientID => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_ADDRESS_REQUEST
    })

    const { data } = await axiosGet(`/api/cs/address/outpatient/${patientID}/fetch`)
    dispatch({
      type: GET_PATIENT_ADDRESS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_ADDRESS_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getFacilityPatientAddressAction = patientID => async dispatch => {
  try {
    dispatch({
      type: "GET_FACILITY_PATIENT_ADDRESS_REQUEST"
    })

    const { data } = await axiosGet(`/api/cs/patients/unitRoom/patient/${patientID}/fetch`)
    dispatch({
      type: "GET_FACILITY_PATIENT_ADDRESS_SUCCESS",
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: "GET_FACILITY_PATIENT_ADDRESS_FAIL",
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postOutPatientAddressAction = (patientID, formData) => async dispatch => {
  try {
    dispatch({
      type: POST_OUTPATIENT_ADDRESS_REQUEST
    })
    const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
    const { data } = await axiosPost(`/api/cs2/address/outpatient/${patientID}/update`, updatedFormData)
    dispatch({
      type: POST_OUTPATIENT_ADDRESS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_OUTPATIENT_ADDRESS_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const updateFacilityPatientAddressAction =
  (patientID, facilityId, formData) => async dispatch => {
    try {
      dispatch({
        type: POST_PATIENT_FACILITY_ADDRESS_REQUEST
      })
      const updatedFormData = { ...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
      const { data } = await axiosPost(
        `/api/cs2/patients/unitRoom/patient/${patientID}/facility/${facilityId}/update`,
        updatedFormData
      )
      dispatch({
        type: POST_PATIENT_FACILITY_ADDRESS_SUCCESS,
        payload: data.details
      })
      return data
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: POST_PATIENT_FACILITY_ADDRESS_FAIL,
        payload: Object.values(error?.response?.data?.details)?.[0] ??
          error?.response?.data?.details?.message
      })
    }
  }

export const postFacilityPatientAddressAction =
  (patientID, facilityId, formData) => async dispatch => {
    try {
      dispatch({
        type: POST_PATIENT_FACILITY_ADDRESS_REQUEST
      })
      const updatedFormData = { ...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
      const { data } = await axiosPost(
        `/api/cs2/patients/unitRoom/patient/${patientID}/facility/${facilityId}/create`,
        updatedFormData
      )
      dispatch({
        type: POST_PATIENT_FACILITY_ADDRESS_SUCCESS,
        payload: data.details
      })
      return data
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: POST_PATIENT_FACILITY_ADDRESS_FAIL,
        payload: Object.values(error?.response?.data?.details)?.[0] ??
          error?.response?.data?.details?.message
      })
    }
  }

// patient report generated
export const getPatientReportAction = (patientId, startDate, endDate) => async dispatch => {
  try {
    dispatch({
      type: GET_REPORT_DATA_REQUEST
    })

    const { data } = await axiosGet(
      `/admin/v1/updatePatientProfile?memberId=${patientId}&startDate=${startDate}&endDate=${endDate}`
    )

    dispatch({
      type: GET_REPORT_DATA_SUCCESS,
      payload: {
        startDate,
        endDate,
        data
      }
    })

    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_REPORT_DATA_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const updateLandlineAction =
  (userId, landlineCode, landNumber, extension) => async dispatch => {
    try {
      dispatch({
        type: UPDATE_PATIENT_LANDLINE_REQUEST
      })

      const LandlineUpdate = {
        userId: userId.userId,
        landlineCode: userId.landlineCode,
        landNumber: userId.landNumber,
        extension: userId.extension
      }

      const { data } = await axiosPost(`/admin/v1/postUpdateUserLandnumber`, LandlineUpdate)

      dispatch({
        type: UPDATE_PATIENT_LANDLINE_SUCCESS,
        payload: JSON.stringify(data)
      })
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: UPDATE_PATIENT_LANDLINE_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

// add concern / consent
export const postConcernAction = (userId, consent, consentFiles) => async dispatch => {
  try {
    dispatch({
      type: POST_CONCERN_REQUEST
    })

    const { data } = await axiosPost(`/caretaker/v1/postConsentDetails`, {
      userId,
      consent,
      consentFiles
    })

    dispatch({
      type: POST_CONCERN_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_CONCERN_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

// get concern / consent
export const getConcernAction = userId => async dispatch => {
  try {
    dispatch({
      type: GET_CONCERN_REQUEST
    })

    const { data } = await axiosLengthyGet(`/caretaker/v1/getConsentDetails?memberId=${userId}`)

    dispatch({
      type: GET_CONCERN_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_CONCERN_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getTriageCriticalPatientsAction = (orgId) => async dispatch => {
  try {
    dispatch({
      type: GET_TRIAGE_CRITICAL_PATIENT_REQUEST
    })
    const userInfo =
      localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
    const { data } = await axiosLengthyGet(`/api/cs/patients/triage/caretaker/${userInfo.id}/org/${orgId}/fetch`)
    dispatch({
      type: GET_TRIAGE_CRITICAL_PATIENT_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_TRIAGE_CRITICAL_PATIENT_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getPatientMonitoringData = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_MONITORING_REQUEST
    })

    // const { data } = await axiosGet(`admin/v1/getUserProfile?memberId=${patientId.patientId}`);
    // const { data } = await axiosGet(`/admin/v2/getUserProfile?memberId=${patientId.patientId}`);
    const { data } = await axiosGet(`/api/cs/patients/monitoring/${patientId.patientId}/fetch`)

    dispatch({
      type: PATIENT_MONITORING_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_MONITORING_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const resetPatientMonitoringData = () => async dispatch => {
  dispatch({
    type: PATIENT_MONITORING_RESET
  })
}

export const getPatientDiagnosticsData = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_DIAGNOSTIC_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/diagnostic/${patientId.patientId}/fetch`)
    dispatch({
      type: PATIENT_DIAGNOSTIC_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_DIAGNOSTIC_FAIL,
      payload: error.response && error?.response?.data?.message
    })
  }
}

export const postPatientDiagnosticsData = (id, formData) => async dispatch => {
  try {
    dispatch({
      type: POST_PATIENT_DIAGNOSTIC_REQUEST
    })
    // const { data } = await axiosGet(`admin/v1/getUserProfile?memberId=${patientId.patientId}`);
    // const { data } = await axiosGet(`/admin/v2/getUserProfile?memberId=${patientId.patientId}`);
    const { data } = await axiosPost(`/api/cs/patients/diagnostic/${id}/create`, formData)

    dispatch({
      type: POST_PATIENT_DIAGNOSTIC_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_PATIENT_DIAGNOSTIC_FAIL,
      payload: error.response && error?.response?.data?.message
    })
  }
}

export const deletePatientDiagnosticsData = (patientId, diagnosticId) => async dispatch => {
  try {
    dispatch({
      type: POST_PATIENT_DIAGNOSTIC_REQUEST
    })
    // const { data } = await axiosGet(`admin/v1/getUserProfile?memberId=${patientId.patientId}`);
    // const { data } = await axiosGet(`/admin/v2/getUserProfile?memberId=${patientId.patientId}`);
    const { data } = await axiosDelete(`/api/cs/patients/${patientId}/diagnostic/${diagnosticId}`)

    dispatch({
      type: POST_PATIENT_DIAGNOSTIC_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_PATIENT_DIAGNOSTIC_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const getPatientVitals = patientId => async dispatch => {
  try {
    dispatch({
      type: PATIENT_VITALS_REQUEST
    })

    // const { data } = await axiosGet(`admin/v1/getUserProfile?memberId=${patientId.patientId}`);
    // const { data } = await axiosGet(`/admin/v2/getUserProfile?memberId=${patientId.patientId}`);
    const { data } = await axiosGet(`/api/cs/patients/vital/${patientId}/fetch`)

    dispatch({
      type: PATIENT_VITALS_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_VITALS_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const postPatientVitals = (patientId, vitalId, formData) => async dispatch => {
  try {
    dispatch({
      type: POST_PATIENT_VITALS_REQUEST
    })
    const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
    const { data } = await axiosPost(
      `/api/cs2/patients/vital/${patientId}/update/${vitalId}`,
      updatedFormData
    )

    await dispatch({
      type: POST_PATIENT_VITALS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_PATIENT_VITALS_FAIL,
      payload: Object?.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const createPatientVitals = (patientId, formData) => async dispatch => {
  try {
    dispatch({
      type: POST_PATIENT_VITALS_REQUEST
    })
    const updatedFormData =  {vitalDetails: formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}

    const { data } = await axiosPost(`/api/cs2/patients/vital/${patientId}/create`, updatedFormData)

    await dispatch({
      type: POST_PATIENT_VITALS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_PATIENT_VITALS_FAIL,
      payload: Object?.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const postMonitoringData = (id, formData) => async dispatch => {
  try {
    dispatch({
      type: POST_MONITORING_REQUEST
    })
    const updatedFormData = {
      monitoringDetails: formData,
      date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
    }
    const { data } = await axiosPost(`/api/cs2/patients/monitoring/${id}/create`, updatedFormData)

    dispatch({
      type: POST_MONITORING_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_MONITORING_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const deleteMonitoringData = (patientID, monitoringID) => async dispatch => {
  try {
    dispatch({
      type: DELETE_MONITORING_REQUEST
    })
    const { data } = await axiosDelete(
      `/api/cs2/patients/${patientID}/monitoringId/${monitoringID}`,
      { data: { date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") } }
    )

    dispatch({
      type: DELETE_MONITORING_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: DELETE_MONITORING_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const getFacilityAddress = () =>
  async dispatch => {
    try {
      const userInfo =
        localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))
      dispatch({
        type: "GET_FACILITY_ADDRESS_REQUEST"
      })
      const { data } = await axiosGet(`/api/cs/address/facility/${userInfo.orgId}/fetchAll`)
      dispatch({
        type: "GET_FACILITY_ADDRESS_SUCCESS",
        payload: data.details
      })
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: "GET_FACILITY_ADDRESS_FAIL",
        payload: error.response && error?.response?.data?.error
      })
    }
  }
  
// for facilityId in the state
export const getfacilityUserAddress = (patientId) => async (dispatch) => {
  try {
    dispatch({ type: GET_FACILITY_USER_ADDRESS_REQUEST })

    const { data } = await axiosGet(`/api/cs/address/facility/${patientId}/fetch`)
    dispatch({
      type: GET_FACILITY_USER_ADDRESS_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_FACILITY_USER_ADDRESS_FAIL,
      payload: Object.values(error?.response?.data?.details)[0] ??
      error?.response?.data?.details?.message
    })
  }
}

export const getFacilityUserAddressReset = () => async (dispatch) => {
  dispatch({ type: GET_FACILITY_USER_ADDRESS_RESET })
}


export const getAdvancedFiltersOptions = (orgId) => async dispatch => {
  try {
    if (!orgId) return
    dispatch({
      type: "GET_ADVANCED_FILTERS_OPTIONS_REQUEST"
    })
    const { data } = await axiosGet(`/api/cs/config/facility/orgId/${orgId}/fetch`)
    dispatch({
      type: "GET_ADVANCED_FILTERS_OPTIONS_SUCCESS",
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: "GET_ADVANCED_FILTERS_OPTIONS_FAIL",
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const getPatientCaretaker = patientID => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_CARETAKER_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/${patientID}/caretaker/fetchAll`)
    dispatch({
      type: GET_PATIENT_CARETAKER_SUCCESS,
      payload: data.details
    })
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_CARETAKER_FAIL,
      payload: error.response && error?.response?.data?.error
    })
  }
}

export const postPatientCaretaker = (patientID, caretakerID) => async dispatch => {
  try {
    dispatch({
      type: "POST_PATIENT_CARETAKER_REQUEST"
    })
    const { data } = await axiosPost(`/api/cs/patients/${patientID}/mapCaretaker/${caretakerID}`)
    dispatch({
      type: "POST_PATIENT_CARETAKER_SUCCESS",
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: "POST_PATIENT_CARETAKER_FAIL",
      payload: error.response && error?.response?.data?.details.message
    })
  }
}

export const deletePatientCaretaker = (patientID, caretakerID) => async dispatch => {
  try {
    dispatch({
      type: "POST_PATIENT_CARETAKER_REQUEST"
    })
    const { data } = await axiosDelete(`/api/cs/patients/${patientID}/mapCaretaker/${caretakerID}`)
    dispatch({
      type: "POST_PATIENT_CARETAKER_SUCCESS",
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: "POST_PATIENT_CARETAKER_FAIL",
      payload: error.response && error?.response?.data?.details.message
    })
  }
}

export const getManualLogTrack = (patientId, month, year) => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_MANUAL_LOG_REQUEST
    })
    let payload
    if (month !== undefined && year !== undefined) {
      payload = { month, year }
    } else {
      payload = {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      }
    }
    // const payload = { month: new Date().getMonth() + 1, year: new Date().getFullYear(), userId: patientId.patientId };
    const { data } = await axiosGet(
      `/api/cs/patients/manualLogTrack/${patientId}/fetch/${payload.month}/${payload.year}`
    )

    dispatch({
      type: GET_PATIENT_MANUAL_LOG_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_MANUAL_LOG_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const addPatientClearErrorsAction = () => async dispatch => {
  dispatch({
    type: ADD_PATIENT_FAIL
  })
}

export const savePatientAdvanceFilter = (data) => async dispatch => {
  dispatch({
    type: "SET_PATIENT_ADVANCE_FILTER",
    payload: data
  })
}
export const resetPatientAdvanceFilter = () => async dispatch => {
  dispatch({
    type: "RESET_PATIENT_ADVANCE_FILTER",
  })
}
export const saveSelectedDateRange = (pickerName, startDate, endDate) => async dispatch => {
  dispatch({ type: "SET_DATE_RANGE", payload: { pickerName, startDate, endDate } })
}

// export const resetSelectedDateRange = (pickerName) => async dispatch =>{
//   dispatch({type: "RESET_DATE_RANGE",
//   payload: { pickerName }
// })
// }

export const getPatientMenuConfigAction = (patientId) => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_MENU_CONFIG_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/menuConfig/${patientId}/fetch`)
    dispatch({
      type: GET_PATIENT_MENU_CONFIG_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_MENU_CONFIG_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const getPatientOptoutAction = (patientId) => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_OPTOUT_REQUEST
    })
    const { data } = await axiosGet(`/api/cs/patients/optout/${patientId}/fetch`)
    dispatch({
      type: GET_PATIENT_OPTOUT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_OPTOUT_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const postPatientOptoutAction = (patientId, formData) => async dispatch => {
  try {
    dispatch({
      type: POST_PATIENT_OPTOUT_REQUEST
    })
    const updatedFormData = {...formData, date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS")}
    const { data } = await axiosPost(`/api/cs2/patients/optout/${patientId}/create`, updatedFormData)
    dispatch({
      type: POST_PATIENT_OPTOUT_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_PATIENT_OPTOUT_FAIL,
      payload: error.response && error?.response?.data?.detail
    })
  }
}

export const patientDetailsValidateAction = (validationData) => async dispatch => {
  try {
    dispatch({
      type: PATIENT_DETAILS_VALIDATE_REQUEST
    })
    const { data } = await axiosPost(`/api/cs/patients/validate`, validationData)
    dispatch({
      type: PATIENT_DETAILS_VALIDATE_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PATIENT_DETAILS_VALIDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const patientDetailsValidateResetAction = () => async dispatch => {
  dispatch({
    type: PATIENT_DETAILS_VALIDATE_RESET
  })
}
