import React, { useState, useEffect } from "react"
import { Button } from "antd"
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Modal } from "react-bootstrap"
import BpLowQues from "../../../data/TriageQuestionBpTwo.json"
import { Progress } from "antd"
import TriageFormInput from "./TriageFormInput"
import HypotensiveNoAlertModal from "./BPModals/HypotensiveNoAlertModal"
import HypotensiveYesAlertModal from "./BPModals/HypotensiveYesAlertModal"
import { postRpmBpTriageAction } from "../../../redux/actions/rpmAction"
import AfterSubmitModal from "./afterSubmitModal.js/AfterSubmitModal"

const TriagePatientLowBpQuesView = ({ readingDetails, patientInfoDetail, triageStartedAt }) => {
  const [ansProgress, setAnsProgress] = useState(0)
  const [ansProgressTwo, setAnsProgressTwo] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [stepOneQues, setStepOneQues] = useState([])
  const [questions, setQuestions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [reRenderer, setReRendered] = useState([])
  const [show, setShow] = useState(false)
  const [isLowRootQues, setIsLowRootQues] = useState([])
  const [hypotensiveQues, setHypotensiveQues] = useState([])
  const [noHypotensiveQues, setNoHypotensiveQues] = useState([])
  const [showLowBpRoot, setShowLowBpRoot] = useState(true)
  const [showLowBpNote, setShowLowBpNote] = useState(false)
  const [showNoHypotensiveQues, setShowNohypotensiveQues] = useState(false)
  //after submit alert
  const [showAfterSubmitAlert, setShowAfterSubmitAlert] = useState(false)
  const [HypotesniveYesAlertQus, setHypotensiveYesAlertQus] = useState({
    id: 20,
    name: "You are currently experiencing signs/symptoms that could indicate a hypotensive crisis. Advise the patient to seek emergency care or dial 911, as they may be experiencing a hypotensive crisis. Please notify the lead RN who will also notify the provider. Document below that you advised the patient to seek emergency care and what the patients response was.",
    text: "",
    quesType: "text"
  })
  const [showHypotensiveYesAlert, setShowHypotensiveYesAlert] = useState(false)
  const [showHypotensiveNoAlert, setShowHypotensiveNoAlert] = useState(false)

  const dispatch = useDispatch()
  const location = useLocation()
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const { id, orgId, orgName } = useParams()

  const { readingIds } = location.state

  useEffect(() => {
    setIsLowRootQues(BpLowQues[0])
    setHypotensiveQues(BpLowQues.slice(1, 4))
    let noHyper = BpLowQues.slice(4, 15)
    let id = 2
    noHyper.map((item, x) => {
      item.id = id
      id += 1
    })
    // setNoHypotensiveQues(BpLowQues.slice(4,17))
    setNoHypotensiveQues(noHyper)
  }, [BpLowQues])

  useEffect(() => {
    setShowModal(true)
  }, [])

  const handleBoolLowBpRootQues = (quesId, e) => {
    //console.log('5',isLowRootQues)
    isLowRootQues.questionaryOptions[0] = e.target.value
    isLowRootQues.questionaryOptions.length
      ? (isLowRootQues.anyValue = true)
      : (isLowRootQues.anyValue = false)
    if (
      isLowRootQues?.name === "Is blood pressure <90 systolically and/or <60 diastolically ?" &&
      isLowRootQues.questionaryOptions[0] === "true"
    ) {
      setShowLowBpNote(true)
    } else {
      setShowLowBpNote(false)
    }
    setIsLowRootQues(isLowRootQues)

    setReRendered([...reRenderer])
  }

  const confirmPopup = () => {
    if (!isLowRootQues.questionaryOptions.length) return alert("Please select an option.")
    if (!showLowBpNote) {
      setQuestions([...noHypotensiveQues])
      setShowModal(false)
      setReRendered([...reRenderer])
      setShow(true)
    } else {
      // let questionToSubmit = isLowRootQues;
      // //console.log(questionToSubmit)
      // handleSubmit(questionToSubmit)
      //navigate(`/patient-more-details/${id}`,{state:{patientLoad:true}})
      setQuestions([...hypotensiveQues])
      setShowModal(false)
      setReRendered([...reRenderer])
      setShow(true)
    }
  }
  //console.log('ques',questions)

  //progressbar handling
  const nextQues = () => {
    if (page < questions.length) {
      setPage(page + 1)
      setAnsProgress(((page + 1) / questions.length) * 100)
    }
  }
  const prevQues = () => {
    if (page > 1) {
      setPage(page - 1)
      // console.log('p',page)
      setAnsProgress(((page - 1) / questions.length) * 100)
    }
  }
  // useEffect(()=>{
  //     if(ansProgress !== 0 && page === questions.length){
  //         setAnsProgress(100)
  //     }
  // },[page])
  // console.log('page',page,'pr0g',ansProgress)

  //=>
  //questions handling
  const handleNormalCheck = (quesId, i, e, val) => {
    // console.log(e.target.checked,quesId,i)
    //console.log('1',questions[quesId-2].questionaryOptions[i-1])
    if (val) {
      //console.log(val)
      questions[quesId - 2].questionaryOptions[i].checked = e.target.checked
      questions[quesId - 2].questionaryOptions.forEach(item => {
        if (item.checked === true) {
          return (questions[quesId - 2].anyValue = true)
        }
      })
      setQuestions([...questions])
      setReRendered([...reRenderer])
    } else {
      questions[quesId - 2].questionaryOptions[i - 1].value[0] = e.target.value
      if (
        questions[quesId - 2].questionaryOptions[i - 1].alert !== undefined &&
        questions[quesId - 2].questionaryOptions[i - 1].value[0] === "true"
      ) {
        // window.alert('Advise the patient to check their blood pressure now.')
        !HypotesniveYesAlertQus?.text?.length && setShowHypotensiveYesAlert(true)
      }
      questions[quesId - 2].questionaryOptions.forEach(item => {
        if (item.checked === true) {
          return (questions[quesId - 2].anyValue = true)
        }
      })
      setQuestions([...questions])
      setReRendered([...reRenderer])
    }

    // console.log('all question list',questions)
  }

  // const handleText = (quesId,i,e)=>{
  //     //console.log('2',questions[quesId-1])
  //     // console.log(e.target.checked,quesId,i)
  //     //console.log(questions[quesId-1].questionaryOptions[i].text)
  //     questions[quesId-1].questionaryOptions[i].text = e.target.value;
  //     questions[quesId-1].questionaryOptions[i].text !== "" ? questions[quesId-1].anyValue = true : questions[quesId-1].anyValue = false;
  //     // questions[quesId-1].questionaryOptions.forEach((item)=>{
  //     //     if(item.checked === true){
  //     //         return questions[quesId-1].anyValue = true;
  //     //     }
  //     // })
  //     setQuestions(questions)
  //     setReRendered([...reRenderer])
  // }
  //=>
  const handleNormalText = (quesId, e) => {
    //console.log('3',questions[quesId-2])
    // console.log(quesId,e.target.value)
    // console.log(questions[quesId-1].questionaryOptions[i].text)
    questions[quesId - 2].text = e.target.value
    questions[quesId - 2].text !== ""
      ? (questions[quesId - 2].anyValue = true)
      : (questions[quesId - 2].anyValue = false)

    setQuestions(questions)
    setReRendered([...reRenderer])
    // console.log('all question list',questions)
  }

  //=>
  const handleBool = (quesId, e) => {
    //console.log('5',questions[quesId-1])
    questions[quesId - 1].questionaryOptions[0] = e.target.value
    questions[quesId - 1].questionaryOptions.length
      ? (questions[quesId - 1].anyValue = true)
      : (questions[quesId - 1].anyValue = false)
    if (
      questions[quesId - 1]?.name ===
      "Is blood pressure >180 systolically and/or >110 diastolically ?" &&
      questions[quesId - 1].questionaryOptions[0] === "true"
    ) {
      setShowLowBpNote(true)
    } else {
      setShowLowBpNote(false)
    }

    setQuestions(questions)
    setReRendered([...reRenderer])
  }
  //=>
  const handleBoolText = (quesId, e, val, tbt) => {
    //if q fortamt is text and bool text
    if (val === null && tbt) {
      //console.log('5',questions[quesId-2]);
      questions[quesId - 2].text = e.target.value
      setQuestions(questions)
      setReRendered([...reRenderer])
    }
    if (val) {
      //console.log('5',questions[quesId-2]);
      questions[quesId - 2].subQuestionaryOptions[0].text = e.target.value
      setQuestions(questions)
      setReRendered([...reRenderer])
    } else {
      //console.log('5',questions[quesId-2])
      questions[quesId - 2].questionaryOptions[0] = e.target.value
      questions[quesId - 2].questionaryOptions.length
        ? (questions[quesId - 2].anyValue = true)
        : (questions[quesId - 2].anyValue = false)
      setQuestions(questions)
      setReRendered([...reRenderer])
    }
    //for bool-text-alert questions
    if (questions[quesId - 2].yesAlert !== undefined && e.target.value === "true") {
      alert(questions[quesId - 2].yesAlert)
    } else if (questions[quesId - 2].noAlert !== undefined && e.target.value === "false") {
      alert(questions[quesId - 2].noAlert)
    }
  }

  //console.log('nohyper',showNoHypotensiveQues)
  const submitFormStepOne = async () => {
    setLoading(true)
    if (!stepOneQues.length) {
      let lastQues = questions[questions.length - 1]
      // console.log('lq',lastQues)
      let show = false
      lastQues?.questionaryOptions?.forEach(x => {
        if (x?.value?.[0] === "false") return (show = true)
      })
      setShowNohypotensiveQues(show)
      let preQues = questions
      preQues.unshift(isLowRootQues)
      preQues.push(HypotesniveYesAlertQus)
      if (show === true) {
        // preQues.push(HypotesniveYesAlertQus)
        setQuestions(noHypotensiveQues)
        setAnsProgressTwo(ansProgress)
        setAnsProgress(0)
        setShowHypotensiveNoAlert(true)
        setStepOneQues(preQues)
        setPage(1)
      } else if (show === false) {
        let questionToSubmit = preQues
        let sno = 1
        questionToSubmit.map(item => {
          item.id = sno
          sno += 1
        })
        //console.log('stepOnesubmit', questionToSubmit)
        handleSubmit(Object.assign({}, questionToSubmit))
      }
      // console.log('preQues',preQues)
    } else {
      //     console.log('step one qeus',stepOneQues)
      // console.log('step tewo qeus',questions)
      let stepOne = stepOneQues
      let sn1 = 1
      stepOne.map(item => {
        item.id = sn1
        sn1 += 1
      })
      let sn2 = stepOneQues.length + 1

      let stepTwo = questions
      stepTwo.map(item => {
        item.id = sn2
        sn2 += 1
      })
      let allQues = [...stepOne, ...stepTwo]
      //console.log('allQues', allQues)
      handleSubmit(Object.assign({}, allQues))
    }
    setLoading(false)
  }

  const handleSubmit = async report => {
    setLoading(true)
    const deviceType = "blood pressure machine"
    //let res = await dispatch(postRpmTriageAction(id,report));
    report.readingDetails = readingDetails
    let res = await dispatch(postRpmBpTriageAction(id, report, readingIds, triageStartedAt, deviceType))
    if (res?.message === "Success") {
      addToast("Plan Added Successfully", { appearance: "success", autoDismiss: true })
      setShowAfterSubmitAlert(true)
      //navigate("/rpm-critical-patient",{replace:true});
    } else {
      addToast("Error Posting Plan", { appearance: "error", autoDismiss: true })
    }
    setLoading(false)
  }

  return (
    <>
      {showLowBpRoot && (
        <Modal
          size="lg"
          centered
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static">
          <Modal.Header>
            <p className="fw-bold mb-0 pb">{isLowRootQues.name}</p>
          </Modal.Header>

          <Modal.Body>
            <>
              <div className="px-4">
                <TriageFormInput ques={isLowRootQues} handleBool={handleBoolLowBpRootQues} />
              </div>
              {showLowBpNote && (
                <div className="px-2 mt-3">
                  <p className="fw-bold mb-0 pb-0">Note to Admin:</p>
                  <p className="fw-bold bg-danger p-2 shadow rounded text-light">
                    This patient may be experiencing a Hypotesnive Crisis which can be a serious,
                    life-threatening condition.
                  </p>
                </div>
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary shadow"
              onClick={() => navigate(`/${orgId}/${orgName}/critical-patient-readings/${patientInfoDetail?.id}`, {
                state: {
                  name: `${patientInfoDetail?.firstName} ${patientInfoDetail?.middleName ?? ""} ${patientInfoDetail?.lastName}`
                }
              })}
            >
              Cancel
            </button>
            <button className="btn btn-info shadow" onClick={confirmPopup}>
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {/* ******************hypertenive alert*********************** */}
      {showHypotensiveYesAlert && (
        <HypotensiveYesAlertModal
          showHypotensiveYesAlert={showHypotensiveYesAlert}
          setShowHypotensiveYesAlert={setShowHypotensiveYesAlert}
          HypotesniveYesAlertQus={HypotesniveYesAlertQus}
          setHypotensiveYesAlertQus={setHypotensiveYesAlertQus}
        />
      )}
      {showHypotensiveNoAlert && (
        <HypotensiveNoAlertModal
          showHypotensiveNoAlert={showHypotensiveNoAlert}
          setShowHypotensiveNoAlert={setShowHypotensiveNoAlert}
        />
      )}
      {/* *************************************** */}
      {show && (
        <div className="px-2">
          <Progress percent={ansProgressTwo} showInfo={false} />
          <Progress percent={ansProgress} showInfo={false} />
        </div>
      )}
      {show && questions.length ? (
        <div className="shadow p-4 rounded">
          {questions?.map(
            (ques, index) =>
              page === index + 1 && (
                <div key={index}>
                  <h4 className="form_heading fw-bold">{ques.name}</h4>
                  <TriageFormInput
                    ques={ques}
                    handleNormalCheck={handleNormalCheck}
                    // handleText={handleText}
                    handleNormalText={handleNormalText}
                    handleBool={handleBool}
                    handleBoolText={handleBoolText}
                  />
                </div>
              )
          )}

          {/* prev next submit btns */}
          <div
            className="button"
            style={{
              marginBottom: "28px",
              marginTop: "46px",
              display: "flex"
            }}>
            {page > 1 && (
              <button
                onClick={prevQues}
                className="back_button"
                style={{ backgroundColor: "#d7a66d", color: "#fff" }}>
                <AiOutlineArrowLeft />
                Back
              </button>
            )}
            {page < questions.length ? (
              <button
                // disabled={page === 48}
                className="next_button"
                style={{ backgroundColor: "#d7a66d", color: "#fff" }}
                onClick={nextQues}>
                Next
                <AiOutlineArrowRight />
              </button>
            ) : (
              <Button
                onClick={submitFormStepOne}
                disabled={loading}
                style={{
                  padding: "3px 50px",
                  backgroundColor: "#d7a66d",
                  borderRadius: "20px",
                  color: "#fff",
                  textTransform: "capitalize"
                }}
                variant="contained">
                {loading ? "Saving..." : "Submit"}
              </Button>
            )}
          </div>
          {/* ******************** */}
        </div>
      ) : null}
      <AfterSubmitModal
        showModal={showAfterSubmitAlert}
        setShowModal={setShowAfterSubmitAlert}
        id={id}
      />
    </>
  )
}

export default TriagePatientLowBpQuesView
