import React, { useState, useEffect } from "react"
import { Button } from "antd"
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai"
import { useDispatch } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { useLocation, useNavigate, useParams } from "react-router-dom"
// import { Modal } from "react-bootstrap";
import { Progress } from "antd"
import TriageFormInput from "./TriageFormInput"
import { postRpmOximeterTriageAction } from "../../../redux/actions/rpmAction"
import OximeterQues from "../../../data/TriageQuesOximeter.json"
import OximeterQuesLessThan50HR from "../../../data/TriageQuesOximeterLessThan50HR.json"
import OxiRoot456AfterQues from "../../../data/TriageQuesOxiRoot456.json"
import OxiRootQuesModel from "./oximeterModals/OxiRootQuesModel"
import OxiHeartRateModal from "./oximeterModals/OxiHeartRateModal"
import HighRateYesAlertModal from "./oximeterModals/HighRateYesAlertModal"
import HeartRateSubNoAlertModal from "./oximeterModals/HeartRateSubNoAlertModal"
import LastQAYesNoModal from "./oximeterModals/LastQAYesNoModal"
import ThirdProgYesAlertModal from "./oximeterModals/ThirdProgYesAlertModal"
import ThirdProgNoAlertModal from "./oximeterModals/ThirdProgNoAlertModal"
// import TriageViewFormInput from './weightScaleModals/TriageViewFormInput';
import OxiTooLowHigh456Modal from "./oximeterModals/OxiTooLowHigh456Modal"
import AfterSubmitModal from "./afterSubmitModal.js/AfterSubmitModal"

const TriageOximeterQuesView = ({ readingDetails, patientInfoDetail, triageStartedAt }) => {
  const [ansProgress, setAnsProgress] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  //=> will be filled after second progress bar.
  const [stepTwoQues, setStepTwoQues] = useState([])
  const [questions, setQuestions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [reRenderer, setReRendered] = useState([])
  // const [show , setShow] = useState(false)
  const [isRootQues, setIsRootQues] = useState([])
  const [isHypoxemiaQues, setIsHypoxemiaQues] = useState([]) //if select 1,2 ,3 of root question
  const [showHypoxemiaQues, setshowHypoxemiaQues] = useState(false)
  const [hypoxemiaLastNoQues, setHypoxemiaLastNoQues] = useState([]) //if selected no to last alert
  const [showHypoxemiaLastNo, setShowHypoxemiaLastNo] = useState(false)
  const [heartRateQues, setHeartRateQues] = useState([]) //after secleting 4,5,6 => yes no for <50 or >120
  const [heartRateSubQuesBothNo, setHeartRateSubQuesBothNo] = useState([])
  const [showHeartRateBothNoQues, setShowHeartRateBothNoQues] = useState(false) //if no to both <50 or >120 ques
  const [heartRateLessThan50Ques, setHeartRateLessThan50Ques] = useState([]) //for <50 HR
  const [showHeartRateLessThan50, setShowHeartRateLessThan50] = useState(false)
  const [heartRateMoreThan120Ques, setHeartRateMoreThan120Ques] = useState([]) //for >120 HR
  const [showHeartRateMoreThan120, setShowHeartRateMoreThan120] = useState(false)
  const [showHeartRateModal, setShowHearRateModal] = useState(false)
  //later update
  const [after456Ques, setAfter456Ques] = useState([])
  const [showAfter456, setShowAfter456] = useState(false)
  const [showOximeterRoot, setShowOximeterRoot] = useState(true)
  const [showHighBpNote, setShowHighBpNote] = useState(false)
  // const [showHeartRateSubNoQues, setShowHeartRateSubNoQues] = useState(false)
  const [showheartRateSubNoAlert, setShowHeartRateSubNoAlert] = useState(false)
  //in case of yes
  const [heartRateSubYesAlertQus, setHeartRateSubYesAlertQus] = useState({
    id: null,
    name: "You are currently experiencing signs/symptoms that could indicate a cardiac emergency. Advise the patient to seek emergency care or dial 911, as they may be experiencing a cardiac emergency. Please notify the lead RN who will also notify the provider. Document below that you advised the patient to seek emergency care and what the patients response was.",
    text: "",
    quesType: "text"
  })
  const [showHighRateYesAlert, setShowHighRateYesAlert] = useState(false)

  //for 1,2,3 selection of main question
  const [oxigenGt90Ques, setOxigenGt90Ques] = useState([])
  const [showOxigenGt90, setShowOxigenGt90] = useState(false)
  const [thirdProgessYesAlertQues, setThirdProgesssYesAlertQues] = useState({
    id: null,
    name: "You are currently experiencing signs/symptoms that could indicate a pulmonary emergency. Advise the patient to seek emergency care or dial 911, as they may be experiencing a pulmonary emergency. Please notify the lead RN who will also notify the provider. Document below that you advised the patient to seek emergency care and what the patients response was",
    text: "",
    quesType: "text"
  })
  const [showThirdProgessYesAlert, setShowThirdProgessYesAlert] = useState(false)
  const [showThirdProgessNoAlert, setShowThirdProgessNoAlert] = useState(false)

  //for disabling first three and last three
  const [disableFirstThree, setDisableFirstThree] = useState(false)
  const [disableLastThree, setDisableLastThree] = useState(false)

  //after submit alert
  const [showAfterSubmitAlert, setShowAfterSubmitAlert] = useState(false)

  const dispatch = useDispatch()
  const location = useLocation()
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const { id } = useParams()

  const { readingIds } = location.state

  useEffect(() => {
    setIsRootQues(OximeterQues[0])
    //setHeartRateQues(OximeterQues[1])//<50 or >120
    let hrsub = OximeterQues.slice(2, 7) //no no to both of above i.e (<50 or >120) =>OximeterQues[2]
    setHeartRateSubQuesBothNo(hrsub)
    setHeartRateLessThan50Ques(OximeterQuesLessThan50HR)
    let hrSubNo = OximeterQues.slice(7, 17) //for >120 selection
    setHeartRateMoreThan120Ques(hrSubNo)
    //if they select 1,2,3, og f main ques
    setOxigenGt90Ques(OximeterQues[17])

    let thirdYes = OximeterQues.slice(18, 23) //if they selected 1,2,3 of isRootQues i.e (OximeterQues[0])
    setIsHypoxemiaQues(thirdYes)

    //setThirdProgessYesQues(thirdYes)
    let fourthNo = OximeterQues.slice(23, 36)
    setHypoxemiaLastNoQues(fourthNo)

    //after 456
    setAfter456Ques(OxiRoot456AfterQues[0])
  }, [OximeterQues])

  useEffect(() => {
    setShowModal(true)
  }, [])

  const handleRootQues = (quesId, i, e) => {
    isRootQues.questionaryOptions[i].checked = e.target.checked
    isRootQues.anyValue = false
    isRootQues.questionaryOptions.forEach(item => {
      if (item.checked === true) {
        return (isRootQues.anyValue = true)
      }
    })

    let firstThree = isRootQues?.questionaryOptions?.slice(0, 3)
    let lastThree = isRootQues?.questionaryOptions?.slice(3)

    if ([0, 1, 2].includes(i) && e.target.checked === true) {
      lastThree.map(item => {
        item.disabled = true
        item.checked = false
      })
    } else if ([3, 4, 5].includes(i) && e.target.checked === true) {
      firstThree.map(item => {
        item.disabled = true
        item.checked = false
      })
    } else {
      let count = 0
      isRootQues.questionaryOptions?.map(item => {
        if (item?.checked === true) count++
      })
      if (count == 0) {
        isRootQues.questionaryOptions?.map(item => {
          item.disabled = false
        })
      }
    }

    setIsRootQues(isRootQues)
    setReRendered([...reRenderer])
  }

  const confirmPopup = () => {
    if (isRootQues.anyValue === false) return alert("Please select an option.")
    setShowModal(false)

    //for option 1,2,3
    isRootQues?.questionaryOptions?.slice(0, 3).map(item => {
      if (item.checked === true) {
        setShowOxigenGt90(true)
      }
    })

    //for option 4,5,6
    isRootQues?.questionaryOptions?.slice(3).map(item => {
      if (item.checked === true) {
        setShowAfter456(true)
        // setShowHearRateModal(true)******************
      }
    })
  }

  //after 456 modal
  const handleAfter456Ques = (quesId, i, e) => {
    after456Ques.questionaryOptions[i - 1].value[0] = e.target.value
    after456Ques.anyValue = false
    after456Ques.questionaryOptions.forEach(item => {
      if (item.value.length) {
        return (after456Ques.anyValue = true)
      }
    })

    setAfter456Ques(after456Ques)
    setReRendered([...reRenderer])
  }

  const confirmAfter456Popup = () => {
    if (after456Ques.anyValue === false) return alert("Please select an option.")
    if (after456Ques.questionaryOptions[0]?.value?.[0] === "true") {
      //<50
      setHeartRateQues(OxiRoot456AfterQues[1])
      setShowAfter456(false)
      setShowHearRateModal(true)
      // setQuestions([...heartRateLessThan50Ques])
      // setShowHeartRateLessThan50(true)
    } else if (after456Ques.questionaryOptions[1]?.value?.[0] === "true") {
      setShowHearRateModal(false)
      setQuestions([...heartRateLessThan50Ques])
      setShowAfter456(false)
      setShowHeartRateLessThan50(true)
      window.alert(
        "The answers to these questions will help you and the patient better understand why they may be experiencing bradycardia. Along the way, educate the patient to make healthy lifestyle changes and incorporate any appropriate nursing interventions/recommendations related to increasing heart rate. Some lifestyle modifications for bradycardia include: healthy diet, track potassium levels, daily exercise, weight loss, reduce caffeine/alcohol/smoking, take cardiac medication as prescribed"
      )
    } else if (after456Ques.questionaryOptions[2]?.value?.[0] === "true") {
      setShowHearRateModal(false)
      setQuestions([...heartRateMoreThan120Ques])
      setShowAfter456(false)
      setShowHeartRateMoreThan120(true)
      window.alert(
        "The answers to these questions will help you and the patient better understand why they may be experiencing tachycardia. Along the way, educate the patient to make healthy lifestyle changes and incorporate any appropriate nursing interventions/recommendations related to lowering heart rate. Some lifestyle modifications for tachycardia include: healthy diet, decrease tyramine rich foods, daily exercise, weight loss, reduce caffeine/alcohol/smoking, take cardiac medication as prescribed"
      )
    } else if (after456Ques.questionaryOptions[3]?.value?.[0] === "true") {
      //>120
      setHeartRateQues(OxiRoot456AfterQues[2])
      setShowAfter456(false)
      setShowHearRateModal(true)
      // setQuestions([...heartRateMoreThan120Ques])
      // setShowHeartRateMoreThan120(true)
    }
  }

  // console.log('ques',questions)
  const handleHeartRateQues = (quesId, i, e) => {
    heartRateQues.questionaryOptions[i - 1].value[0] = e.target.value
    heartRateQues.anyValue = false
    heartRateQues.questionaryOptions.forEach(item => {
      if (item.value.length) {
        return (heartRateQues.anyValue = true)
      }
    })

    setHeartRateQues(heartRateQues)
    setReRendered([...reRenderer])
    //console.log('hrq',heartRateQues)
  }

  const confirmHeartRatePopup = () => {
    if (heartRateQues.anyValue === false) return alert("Please select an option.")
    //new logic
    if (
      heartRateQues.questionaryOptions[0]?.value?.[0] === "true" &&
      heartRateQues.questionaryOptions[0]?.name === "A heart rate <50 BPM?"
    ) {
      setShowHearRateModal(false)
      setQuestions([...heartRateLessThan50Ques])
      setShowHeartRateLessThan50(true)
    } else if (
      heartRateQues.questionaryOptions[0]?.value?.[0] === "true" &&
      heartRateQues.questionaryOptions[0]?.name === "A heart rate >120 BPM?"
    ) {
      setShowHearRateModal(false)
      setQuestions([...heartRateMoreThan120Ques])
      setShowHeartRateMoreThan120(true)
    } else {
      setShowHearRateModal(false)
      setQuestions([...heartRateSubQuesBothNo])
      setShowHeartRateBothNoQues(true)
    }

    //prev login
    // if(heartRateQues.questionaryOptions[0]?.value?.[0] === 'true'){//<50
    //     setShowHearRateModal(false)
    //     setQuestions([...heartRateLessThan50Ques])
    //     setShowHeartRateLessThan50(true)
    // }else if(heartRateQues.questionaryOptions[1]?.value?.[0] === 'true'){//>120
    //     setShowHearRateModal(false)
    //     setQuestions([...heartRateMoreThan120Ques])
    //     setShowHeartRateMoreThan120(true)
    // }
    // else{
    //     //console.log('Both No')
    //     setShowHearRateModal(false)
    //     setQuestions([...heartRateSubQuesBothNo])
    //     setShowHeartRateBothNoQues(true)

    // }
  }
  //
  //questions handling
  const handleNormalCheck = (quesId, i, e, val) => {
    let qIds = questions.findIndex(x => x.id === quesId)
    //console.log(qIds)
    // //for last yes/no:
    // console.log('quis',qIds)
    // if(qIds === 9){
    //     let show = false;
    //     questions[qIds].questionaryOptions?.slice(0,3).map((x)=>{
    //             if(x?.checked === true) return show = true;
    //     })
    // }
    // console.log(e.target.checked,quesId,i)
    // console.log('1',questions[quesId-1].questionaryOptions[i-1])
    if (val) {
      //console.log(val)
      questions[qIds].questionaryOptions[i].checked = e.target.checked
      questions[qIds].questionaryOptions.forEach(item => {
        if (item.checked === true) {
          return (questions[qIds].anyValue = true)
        }
      })
      setQuestions([...questions])
      setReRendered([...reRenderer])
    } else {
      questions[qIds].questionaryOptions[i - 1].value[0] = e.target.value
      if (
        questions[qIds].questionaryOptions[i - 1].alert !== undefined &&
        questions[qIds].questionaryOptions[i - 1].value[0] === "true"
      ) {
        // window.alert('Advise the patient to check their blood pressure now.')
        if (stepTwoQues.length) {
          !thirdProgessYesAlertQues?.text?.length && setShowThirdProgessYesAlert(true)
        } else if (showHypoxemiaQues === true)
          !thirdProgessYesAlertQues?.text?.length && setShowThirdProgessYesAlert(true)
        else {
          !heartRateSubYesAlertQus?.text?.length && setShowHighRateYesAlert(true)
        }
      }
      questions[qIds].questionaryOptions.forEach(item => {
        if (item.checked === true) {
          return (questions[qIds].anyValue = true)
        }
      })
      setQuestions([...questions])
      setReRendered([...reRenderer])
    }
  }

  //=>
  const handleNormalText = (quesId, e) => {
    let qIds = questions.findIndex(x => x.id === quesId)
    questions[qIds].text = e.target.value
    questions[qIds].text !== ""
      ? (questions[qIds].anyValue = true)
      : (questions[qIds].anyValue = false)

    setQuestions(questions)
    setReRendered([...reRenderer])
  }

  //=>
  const handleBool = (quesId, e) => {
    let qIds = questions.findIndex(x => x.id === quesId)
    questions[qIds].questionaryOptions[0] = e.target.value
    questions[qIds].questionaryOptions.length
      ? (questions[qIds].anyValue = true)
      : (questions[qIds].anyValue = false)
    if (
      questions[qIds]?.name === "Is blood pressure >180 systolically and/or >110 diastolically ?" &&
      questions[qIds].questionaryOptions[0] === "true"
    ) {
      setShowHighBpNote(true)
    } else {
      setShowHighBpNote(false)
    }

    setQuestions(questions)
    setReRendered([...reRenderer])
  }
  //=>
  const handleBoolAlert = (quesId, e) => {
    let qIds = questions.findIndex(x => x.id === quesId)
    questions[qIds].questionaryOptions[0] = e.target.value
    questions[qIds].questionaryOptions.length
      ? (questions[qIds].anyValue = true)
      : (questions[qIds].anyValue = false)
    if (questions[qIds].questionaryOptions[0] === "true") {
      alert(questions[qIds]?.yesAlert)
    }
    setQuestions(questions)
    setReRendered([...reRenderer])
  }
  //=>
  const handleBoolText = (quesId, e, val, tbt) => {
    //if q fortamt is text and bool text
    let qIds = questions.findIndex(x => x.id === quesId)
    if (val === null && tbt) {
      questions[qIds].text = e.target.value
      setQuestions(questions)
      setReRendered([...reRenderer])
    }
    if (val) {
      questions[qIds].subQuestionaryOptions[0].text = e.target.value
      setQuestions(questions)
      setReRendered([...reRenderer])
    } else {
      questions[qIds].questionaryOptions[0] = e.target.value
      questions[qIds].questionaryOptions.length
        ? (questions[qIds].anyValue = true)
        : (questions[qIds].anyValue = false)
      setQuestions(questions)
      setReRendered([...reRenderer])
    }
    //for bool-text-alert questions
    if (questions[qIds].yesAlert !== undefined && e.target.value === "true") {
      alert(questions[qIds].yesAlert)
    }
    // else if(questions[quesId-1].noAlert !== undefined && e.target.value === 'false'){
    //     alert(questions[quesId-1].noAlert)
    // }
  }

  //for last q/a for third/fourth progress bar
  const handleOxiGt90Bool = (quesId, e) => {
    oxigenGt90Ques.questionaryOptions[0] = e.target.value
    oxigenGt90Ques.questionaryOptions.length
      ? (oxigenGt90Ques.anyValue = true)
      : (oxigenGt90Ques.anyValue = false)
    setOxigenGt90Ques(oxigenGt90Ques)
    setReRendered([...reRenderer])
  }

  //progressbar handling
  const nextQues = () => {
    if (page < questions.length) {
      setPage(page + 1)
      setAnsProgress(((page + 1) / questions.length) * 100)
    }
  }
  const prevQues = () => {
    if (page > 1) {
      setPage(page - 1)
      setAnsProgress(((page - 1) / questions.length) * 100)
    }
  }
  //
  const submitFormStepOne = async () => {
    setLoading(true)

    //for HypoxemiaQues => if selected 1,2,3 of root ques earlier
    if (showHypoxemiaQues === true) {
      let preQues = questions
      preQues.unshift(isRootQues)
      preQues.push(thirdProgessYesAlertQues)
      let questionToSubmit = preQues
      let sno = 1
      questionToSubmit.map(item => {
        item.id = sno
        sno += 1
      })
      handleSubmit(Object.assign({}, questionToSubmit))
      setshowHypoxemiaQues(false)
    }
    if (showHypoxemiaLastNo) {
      let preQues = questions
      preQues.unshift(isRootQues)
      let questionToSubmit = preQues
      let sno = 1
      questionToSubmit.map(item => {
        item.id = sno
        sno += 1
      })
      handleSubmit(Object.assign({}, questionToSubmit))
      setShowHypoxemiaLastNo(false)
    }

    if (showHeartRateBothNoQues === true) {
      let preQues = questions
      preQues.unshift(isRootQues)
      preQues.push(heartRateSubYesAlertQus)
      let questionToSubmit = preQues
      let sno = 1
      questionToSubmit.map(item => {
        item.id = sno
        sno += 1
      })

      handleSubmit(Object.assign({}, questionToSubmit))
      setShowHeartRateBothNoQues(false)
    }

    if (showHeartRateLessThan50 === true) {
      let preQues = questions
      preQues.unshift(isRootQues)
      let questionToSubmit = preQues
      let sno = 1
      questionToSubmit.map(item => {
        item.id = sno
        sno += 1
      })

      handleSubmit(Object.assign({}, questionToSubmit))

      setShowHeartRateLessThan50(false)
    }

    if (showHeartRateMoreThan120 === true) {
      let preQues = questions
      preQues.unshift(isRootQues)
      let questionToSubmit = preQues
      let sno = 1
      questionToSubmit.map(item => {
        item.id = sno
        sno += 1
      })

      handleSubmit(Object.assign({}, questionToSubmit))

      setShowHeartRateMoreThan120(false)
    }
    setLoading(false)
  }

  const OxiGt90YesNoHandler = () => {
    if (!oxigenGt90Ques.questionaryOptions[0]?.length) return alert("Please select an option.")

    if (oxigenGt90Ques.questionaryOptions[0] === "true") {
      setShowOxigenGt90(false)
      setshowHypoxemiaQues(true)
      setQuestions([...isHypoxemiaQues])
    } else if (oxigenGt90Ques.questionaryOptions[0] === "false") {
      setShowOxigenGt90(false)
      setShowHypoxemiaLastNo(true)
      setQuestions([...hypoxemiaLastNoQues])
    }
  }

  //final submit
  const handleSubmit = async report => {
    setLoading(true)
    report.readingDetails = readingDetails
    //let res = await dispatch(postRpmTriageAction(id,report));
    const deviceType = "oximeter"
    let res = await dispatch(postRpmOximeterTriageAction(id, report, readingIds, triageStartedAt, deviceType ))
    if (res?.message === "Success") {
      addToast("Plan Added Successfully", { appearance: "success", autoDismiss: true })
      setShowAfterSubmitAlert(true)
      //window.location.href='/rpm-critical-patient';
    } else {
      addToast("Error Posting Plan", { appearance: "error", autoDismiss: true })
    }
    //.log('report',report)
    // setTestData(Object.values(report))
    // setShowTestData(true)
    setLoading(false)
  }

  return (
    <>
      {showOximeterRoot && (
        <OxiRootQuesModel
          showModal={showModal}
          setShowModal={setShowModal}
          isRootQues={isRootQues}
          handleRootQues={handleRootQues}
          confirmPopup={confirmPopup}
          disableFirstThree={disableFirstThree}
          disableLastThree={disableLastThree}
          patientInfoDetail={patientInfoDetail}
        />
      )}
      {showAfter456 && (
        <OxiTooLowHigh456Modal
          showModal={showAfter456}
          setShowModal={setShowAfter456}
          ques={after456Ques}
          handleQues={handleAfter456Ques}
          confirmPopup={confirmAfter456Popup}
          patientInfoDetail={patientInfoDetail}
        />
      )}
      {showHeartRateModal && (
        <OxiHeartRateModal
          showModal={showHeartRateModal}
          setShowModal={setShowHearRateModal}
          heartRateQues={heartRateQues}
          handleQues={handleHeartRateQues}
          confirmPopup={confirmHeartRatePopup}
          patientInfoDetail={patientInfoDetail}
        />
      )}
      {/* alert ques if click on yes to last ques of first progress bar */}
      {showHighRateYesAlert && (
        <HighRateYesAlertModal
          showHighRateYesAlert={showHighRateYesAlert}
          setShowHighRateYesAlert={setShowHighRateYesAlert}
          heartRateSubYesAlertQus={heartRateSubYesAlertQus}
          setHeartRateSubYesAlertQus={setHeartRateSubYesAlertQus}
        />
      )}
      {showheartRateSubNoAlert && (
        <HeartRateSubNoAlertModal
          showheartRateSubNoAlert={showheartRateSubNoAlert}
          setShowHeartRateSubNoAlert={setShowHeartRateSubNoAlert}
        />
      )}
      {showOxigenGt90 && (
        <LastQAYesNoModal
          showModal={showOxigenGt90}
          setShowModal={setShowOxigenGt90}
          ques={oxigenGt90Ques}
          handleQues={handleOxiGt90Bool}
          confirmPopup={OxiGt90YesNoHandler}
          patientInfoDetail={patientInfoDetail}
        />
      )}
      {showThirdProgessYesAlert && (
        <ThirdProgYesAlertModal
          showModal={showThirdProgessYesAlert}
          setShowModal={setShowThirdProgessYesAlert}
          ques={thirdProgessYesAlertQues}
          setQues={setThirdProgesssYesAlertQues}
        />
      )}
      {showThirdProgessNoAlert && (
        <ThirdProgNoAlertModal
          showModal={showThirdProgessNoAlert}
          setShowModal={setShowThirdProgessNoAlert}
        />
      )}

      {/* *************************************** */}
      <Progress percent={ansProgress} showInfo={false} />
      {(showHypoxemiaQues ||
        showHeartRateBothNoQues ||
        showHeartRateLessThan50 ||
        showHeartRateMoreThan120 ||
        showHypoxemiaLastNo) &&
        questions.length ? (
        <div className="shadow p-4 rounded">
          {questions?.map(
            (ques, index) =>
              page === index + 1 && (
                <div key={index}>
                  <h4 className="form_heading fw-bold">{ques.name}</h4>
                  <TriageFormInput
                    ques={ques}
                    handleNormalCheck={handleNormalCheck}
                    handleNormalText={handleNormalText}
                    handleBool={handleBool}
                    handleBoolText={handleBoolText}
                    handleBoolAlert={handleBoolAlert}
                  />
                </div>
              )
          )}

          {/* prev next submit btns */}
          <div
            className="button"
            style={{
              marginBottom: "28px",
              marginTop: "46px",
              display: "flex"
            }}>
            {page > 1 && (
              <button
                onClick={prevQues}
                className="back_button"
                style={{ backgroundColor: "#d7a66d", color: "#fff" }}>
                <AiOutlineArrowLeft />
                Back
              </button>
            )}
            {page < questions.length ? (
              <button
                // disabled={page === 48}
                className="next_button"
                style={{ backgroundColor: "#d7a66d" }}
                onClick={nextQues}>
                Next
                <AiOutlineArrowRight />
              </button>
            ) : (
              <Button
                onClick={submitFormStepOne}
                disabled={loading}
                style={{
                  padding: "3px 50px",
                  backgroundColor: "#d7a66d",
                  borderRadius: "20px",
                  color: "#fff",
                  textTransform: "capitalize"
                }}
                variant="contained">
                {loading ? "Saving..." : "Submit"}
              </Button>
            )}
          </div>
          {/* ******************** */}
        </div>
      ) : null}
      {/* {showTestData && testData.length ?
        <div>
            <h4 className='my-4 text-danger'>Note: This Q/A is not submitting to server. It's just for testing.</h4>
            <hr></hr>
        {testData?.map((ques, index)=>(
            <div key={index}>
                <h4 className="form_heading fw-bold">{ques.name}</h4>
            <TriageViewFormInput ques={ques}/>
            <hr></hr>
            </div>
        ))}
        <hr></hr>
        <button className='btn btn-dark' onClick={()=> window.location.href='/rpm-critical-patient'}>Close</button>
        </div>
    :
    null
    } */}
      <AfterSubmitModal
        showModal={showAfterSubmitAlert}
        setShowModal={setShowAfterSubmitAlert}
        id={id}
      />
    </>
  )
}

export default TriageOximeterQuesView