import React, { useEffect, useState, memo } from "react"
import { useNavigate, useParams } from "react-router-dom"

import moment from "moment"
import { Button, Table, Input, Card, Tooltip, Radio, Select } from "antd"
import {
  EyeOutlined,
  EditOutlined,
  LinkOutlined,
  PlusOutlined,
  HistoryOutlined
} from "@ant-design/icons"

import { useDispatch, useSelector } from "react-redux"
import { getrpmDataActions } from "../../redux/actions/rpmAction"

import Loading from "../layouts/Loading/Loading"
import { filter } from "lodash"
import { Mixpanel } from "../../utils/mixpanel"
import { getAdvancedFiltersOptions } from "../../redux/actions/patientAction"
import OrgBackNavigation from "../Organization/OrgBackNavigation"

const { Search } = Input

const getManupulatedData = data => {
  return data?.map(
    (
      {
        firstName,
        lastName,
        middleName,
        id,
        email,
        dob,
        userType,
        facilityId,
        rpmReadingCount,
        treatmentplanDate,
        createdByFirstName,
        createdByMiddleName,
        createdByLastName
      },
      index
    ) => ({
      key: index + 1,
      sNo: index + 1,
      id,
      email,
      dob,
      firstName,
      middleName,
      lastName,
      userType,
      facilityId,
      rpmReadingCount,
      treatmentplanDate,
      name:
        !middleName || middleName === null
          ? `${firstName} ${lastName}`
          : `${firstName} ${middleName} ${lastName}`,
      createdBy:
        createdByFirstName && createdByFirstName !== ""
          ? `${createdByFirstName}${
              createdByMiddleName && createdByMiddleName !== "" ? " " + createdByMiddleName : ""
            } ${createdByLastName} `
          : "-"
    })
  )
}

const getDaysDifference = (date) => {
  if (!date) return 0;
  const creationDate = new Date(date);
  const today = new Date();
  const diffTime = Math.abs(today - creationDate);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const isPlanExpired = (date) => {
  return getDaysDifference(date) > 365;
};

const isInFollowUpPeriod = (date) => {
  const days = getDaysDifference(date);
  return days >= 365 && days <= 730; // Between 1 and 2 years
};

const RPMIsNewPatient = () => {
  const dispatch = useDispatch()
  const { loading, getRpm } = useSelector(state => state.rpmList)
  const { userInfo } = useSelector(state => state.userInfo)
  const { advancedFiltersOptions, loading: advanceFilterloading } = useSelector(state => state.advancedFiltersOptions)
  const [RpmData, setRpmData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedPlan, setSelectedPlan] = useState("all-plan")
  const [selectedFacility, setSelectedFacility] = useState("both")
  const [facilityFilteredValues, setFacilityFilteredValues] = useState()
  const facilityOptions = advancedFiltersOptions?.filter(i => i.userType === "facility") || []

  const navigate = useNavigate()
  const {orgId, orgName} = useParams()

  useEffect(() => {
    if (userInfo) dispatch(getrpmDataActions( orgId ))
  }, [dispatch, userInfo])

  // column for table
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      render: (name, { email, id , facilityId}) => (
        <div
          className="cursor-pointer hover:text-current"
          onClick={() => {
            navigate(`/${orgId}/${orgName}/patient/${id}`,{state:{facilityId}})
          }}>
          <p className="text-capitalize wrap-text mb-1">{name}</p>
          <p className="text-secondary text-xs">{email}</p>
        </div>
      ),
      sorter: (a, b) => a?.name.localeCompare(b?.name)
    },
    // {
    //   title: "Created By",
    //   dataIndex: "createdBy",
    //   key: "createdBy",
    //   render: createdBy => (
    //     <p className="text-capitalize wrap-text mb-0">
    //       {createdBy ? createdBy : ''}
    //     </p>
    //   )
    // },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      render: userType => <p className="text-capitalize wrap-text mb-0">{userType}</p>,
      sorter: (a, b) => a?.userType.localeCompare(b?.userType)
    },
    {
      title: "Treatment Plan Date",
      dataIndex: "treatmentplanDate",
      key: "treatmentplanDate",
      render: treatmentplanDate => (
        <p className="text-capitalize wrap-text mb-0">
          {treatmentplanDate && treatmentplanDate.length
            ? `${new Date(treatmentplanDate).toDateString()}`
            : "N/A"}
        </p>
      ),
      sorter: (a, b) => a?.treatmentplanDate.localeCompare(b?.treatmentplanDate)
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: createdBy => <p className="capitalize">{createdBy}</p>,
      sorter: (a, b) => a?.createdBy?.localeCompare(b?.createdBy)
    },
    {
      title: "Actions",
      render: (treatmentplan, { id, treatmentplanDate }) => (
        <div className="flex items-center gap-2 flex-wrap">
          <Tooltip title="Create Plan">
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              onClick={() => navigate(`/${orgId}/${orgName}/rpm-questions/${id}`, {
                state: {
                  name: {
                    firstName: treatmentplan.firstName,
                    middleName: treatmentplan.middleName,
                    lastName: treatmentplan.lastName,
                  },
                  dob: treatmentplan.dob,
                }
              })}
            />
          </Tooltip>

          {treatmentplanDate && treatmentplanDate.length ? (
            <>
              <Tooltip title="View Plan">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    Mixpanel.track("Treatement Plan Clicked")
                    navigate(`/${orgId}/${orgName}/rpm-view-treatement/${id}`, {
                      state: {
                        name: {
                          firstName: treatmentplan.firstName,
                          middleName: treatmentplan.middleName,
                          lastName: treatmentplan.lastName,
                        },
                        dob: treatmentplan.dob,
                      }
                    })
                  }}
                />
              </Tooltip>
              {!isPlanExpired(treatmentplanDate) ? (
                <Tooltip title="Update Plan">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => navigate(`/${orgId}/${orgName}/rpm-edit-treatement/${id}`)}
                  />
                </Tooltip>
              ) : null}
              <Tooltip title="Plan History">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<HistoryOutlined />}
                  onClick={() => navigate(`/${orgId}/${orgName}/rpm-plan-history/${id}`, {
                    state: {
                      name: {
                        firstName: treatmentplan.firstName,
                        middleName: treatmentplan.middleName,
                        lastName: treatmentplan.lastName,
                      },
                      dob: treatmentplan.dob,
                    }
                  })}
                />
              </Tooltip>
              {isInFollowUpPeriod(treatmentplanDate) ? (
                <Tooltip title="Follow Up">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<LinkOutlined />}
                    onClick={() => navigate(`/${orgId}/${orgName}/rpm-follow-up/${id}`, {
                      state: {
                        name: {
                          firstName: treatmentplan.firstName,
                          middleName: treatmentplan.middleName,
                          lastName: treatmentplan.lastName,
                        },
                        dob: treatmentplan.dob,
                      }
                    })}
                  />
                </Tooltip>
              ) : null}
            </>
          ) : null}
        </div>
      )
    }
  ]

  useEffect(() => {
    if (!getRpm?.length) return
    const sortedData = getRpm?.sort((a, b) => b.treatmentplanDate.localeCompare(a.treatmentplanDate))
    setRpmData(getManupulatedData(sortedData))
  }, [getRpm])

  const onChange = ({ target: { value } }) => {
    setSelectedPlan(value)
  }

  const facilityOnChange = ({ target: { value } }) => {
    setSelectedFacility(value)
  }

  useEffect(() => {
    if( selectedFacility === "facility" && !advancedFiltersOptions?.length )
    dispatch(getAdvancedFiltersOptions(orgId))
  }, [selectedFacility])

  useEffect(() => {
    let filterData = getRpm;

    if (searchTerm) {
      const trimmedSearchValue = searchTerm.replace(/\s+/g, "").toLowerCase()
      filterData = filterData?.filter(
        item =>
          (item?.middleName
            ? `${item?.firstName}${item?.middleName}${item?.lastName}`
            : `${item?.firstName}${item?.lastName}`
          )
            ?.toLowerCase()
            ?.includes(trimmedSearchValue) ||
          item?.email?.toLowerCase().includes(trimmedSearchValue)
      )
      if (!filterData?.length) {
        return setRpmData([])
      }
    }

    if (selectedFacility !== "both") {
      if (selectedFacility === "facility" && facilityFilteredValues) {
        filterData = filterData?.filter(
          ({ userType, facilityId }) =>
            userType === selectedFacility && facilityFilteredValues === facilityId
        )
      } else filterData = filterData?.filter(({ userType }) => userType === selectedFacility)
    }

    if (selectedPlan === "new-plan") {
      filterData = filterData?.filter(({ treatmentplanDate }) =>
        !treatmentplanDate || isPlanExpired(treatmentplanDate)
      );
    } else if (selectedPlan === "added-plan") {
      filterData = filterData?.filter(({ treatmentplanDate }) =>
        treatmentplanDate && !isPlanExpired(treatmentplanDate)
      );
    } else if (selectedPlan === "follow-up") {
      filterData = filterData?.filter(({ treatmentplanDate }) =>
        isInFollowUpPeriod(treatmentplanDate)
      );
    }

    setRpmData(getManupulatedData(filterData))
  }, [searchTerm, selectedPlan, selectedFacility, facilityFilteredValues])

  const handleSearch = (e) => {
    const query = e?.target?.value
    let queryWithoutSpace = query?.replace(/^\s+/g, "")
    queryWithoutSpace = queryWithoutSpace?.replace(/\s{2,}/g, " ")
    setSearchTerm(queryWithoutSpace)
  }

  return loading ? (
    <div className="loaderLoading">
      <Loading />
    </div>
  ) : (
    <>
      <OrgBackNavigation />
      <Card className="mt-2" title={<h4 className="text-xl">Filters</h4>}>
        <div className="flex justify-between items-center gap-3 flex-wrap">
          <Search
            allowClear
            size="large"
            placeholder="Search"
            className="max-w-[300px]"
            value={searchTerm}
            // onSearch={setSearchTerm}
            onChange={e => handleSearch(e)}
          />
          <Radio.Group
            optionType="button"
            onChange={onChange}
            value={selectedPlan}
            options={[
              {
                label: "All Plans",
                value: "all-plan"
              },
              {
                label: "New Plan (Pending)",
                value: "new-plan"
              },
              {
                label: "Added Plan (Done)",
                value: "added-plan"
              },
              {
                label: "Follow Up (Inactive)",
                value: "follow-up"
              }
            ]}
          />
          <Radio.Group
            optionType="button"
            value={selectedFacility}
            onChange={facilityOnChange}
            options={[
              {
                label: "Both",
                value: "both"
              },
              {
                label: "Facility",
                value: "facility"
              },
              {
                label: "Outpatient",
                value: "outpatient"
              }
            ]}
          />
          {selectedFacility === "facility" && (
            <Select
              className="md:ml-auto w-56"
              placeholder="Select Facility"
              options={facilityOptions
                ?.sort((a, b) => a.name.localeCompare(b.name)) // Sort the options alphabetically by name
                ?.map(address => ({
                  value: address.facilityId,
                  label: (
                    <Tooltip title={<span className="capitalize">{address.name}</span>}>
                      <span className="capitalize">{address.name}</span>
                    </Tooltip>
                  )
                }))}
              value={facilityFilteredValues || null}
              allowClear
              onChange={val => {
                setFacilityFilteredValues(val)
              }}
            />
          )}
        </div>
      </Card>
      <Card className="rounded-b-none mt-4">
        <h3 className="text-xl">Treatment Plans</h3>
      </Card>
      <Table
        columns={columns}
        dataSource={RpmData}
        scroll={{ x: "max-content" }}
      />
    </>
  )
}

export default memo(RPMIsNewPatient)
