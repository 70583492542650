import { USER_SEARCH_REQUEST, USER_SEARCH_FAIL, USER_SEARCH_SUCCESS } from "../types/userSearchConstant"

const userInfo = localStorage.getItem("userInfo")
const userInfoFromStorage = userInfo !== null ? JSON.parse(userInfo) : null

const initialState = {
  userInfo: { loading: false, userInfo: userInfoFromStorage }
}

export const getUserSearchnReducer = (state = initialState.userInfo, action) => {
  switch (action.type) {
    case USER_SEARCH_REQUEST:
      return { loading: true }
    case USER_SEARCH_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_SEARCH_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
